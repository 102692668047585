import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {html} from "lit";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-product-list",
  class PageProductList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = true;
    searchable = true;
    title = 'Product List';

    editUrl(obj) {
      return `/products/${obj.id}`;
    }
    newUrl() {
      return `/products/new`;
    }

    constructor() {
      super(new GenericDomain('/products'));
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, searchable: true},
        {name: 'Price', field: 'price', sortable: true},
        {name: 'Currency', field: 'currency_id', sortable: true, render: (obj) => {
          return html`${obj.currency?.code}`;
        }},
        {name: 'Program', field: 'program_id', sortable: true, render: (obj) => {
          if (obj.program) return html`
            <a href="/programs/${obj.program_id}">
              ${obj.program.name}
            </a>
          `;
          return html`-`;
        }},
      ];
    }
  }
);
