import {RoleMixin} from "../../shared/pwa-page";
import {AdminViewPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {UserDomain} from "../../domain/user-domain";
import {html, nothing} from "lit";
import {repeat} from "lit/directives/repeat.js";

customElements.define(
  "page-user-view",
  class PageUserView extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminViewPage) {

    constructor() {
      super(new UserDomain());
    }

    renderPrograms() {
      return html`
        <section class="card">
          <h3>Programs</h3>
          ${this.object.programs ? html`
            <div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th>Program</th>
                    <th>Started at</th>
                    <th>Status</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody>
                ${repeat(this.object.programs, (program) => html`
                  <tr>
                    <td>
                      <a href="/programs/${program.id}">${program.name}</a>
                    </td>
                    <td>
                      ${(new Date(program.pivot.start_date)).format({mode: 'format', timeStyle: undefined})}
                    </td>
                    <td>
                      ${program.pivot.completed ? html`Completed` : html`Running...`}
                    </td>
                    <td>
                      ${program.pivot.order_id ? html`
                        <a href="/orders/${program.pivot.order_id}">View</a>
                      ` : html`-`}
                    </td>
                  </tr>
                `)}
                </tbody>
              </table>
            </div>
          ` : html`
            <i>User has no programs</i>
          `}
        </section>
      `;
    }

    renderQuestionnaires() {
      return html`
        <section class="card">
          <h3>Questionnaires</h3>
          ${this.object.questionnaires ? html`
            <div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th>Questionnaire</th>
                    <th>Last change</th>
                    <th>Status</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody>
                ${repeat(this.object.questionnaires, (questionnaire) => {
                  const isCompleted = questionnaire.current_step === questionnaire.total;
                  const isDeleted = questionnaire.deleted_at !== null;
                  return html`
                  <tr>
                    <td>
                      <a href="/questionnaires/category/${questionnaire.questionnaire_category?.id}">
                        ${questionnaire.questionnaire_category?.name ?? '-unknown-'}
                      </a>
                      ${isDeleted ? html`&dagger;` : nothing}
                    </td>
                    <td>
                      ${(new Date(questionnaire.updated_at)).format({mode: 'format', timeStyle: undefined})}
                    </td>
                    <td>
                      ${isCompleted ? html`Completed` : html`Running (${questionnaire.current_step}/${questionnaire.total})...`}
                    </td>
                    <td>
                      ${isCompleted ? html`
                        <a href="/questionnaires/${questionnaire.id}/view?user_id=${this.object.id}" class="button tiny primary">
                          View
                        </a>
                      ` : nothing}
                      <a href="/questionnaires/${questionnaire.id}/destroy?user_id=${this.object.id}"
                         class="button tiny red outline ${isDeleted ? 'disabled' : ''}"
                         title="${isDeleted ? 'Already deleted' : ''}">
                        Delete
                      </a>
                    </td>
                  </tr>
                `
                })}
                </tbody>
              </table>
            </div>
          ` : html`
            <i>No questionnaires found for the user (none started)</i>
          `}
        </section>
      `;
    }

    renderDetail(obj) {
      return html`
        <section class="card">
          <flex-container breakpoint="tiny">
            <flex-item>
              <a href="javascript:history.back()" class="button round tiny">Back</a>
            </flex-item>
            <flex-item>
              <h1>User: ${obj.firstname} ${obj.lastname}</h1>
            </flex-item>
          </flex-container>
        </section>

        <flex-container breakpoint="normal">
          <flex-item class="col-6">
            <section class="card">
              <h3>Personal details</h3>
              <div class="data-table">
                <table>
                  <tbody>
                    <tr>
                      <td class="bold">Image</td>
                      <td>
                        <img alt="profile picture"
                             src="${this.object.profile_img_url ?? '/assets/img/profile-picture.webp'}"
                             loading="lazy" class="rounded" style="height: 50px;" />
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">Name</td>
                      <td>
                        ${this.object.firstname} ${this.object.lastname}
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">Email</td>
                      <td>
                        ${this.object.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </flex-item>

          <flex-item class="col-6">
            <section class="card">
              <h3>Company</h3>
              ${obj.organisation ? html`
                TODO:
                <div class="data-table">
                  <table>
                    <tbody>
                    <tr>
                      <td class="bold">User-Agent</td>
                      <td>
                        ${this.object.user_agent}
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">IP-address</td>
                      <td>
                        ${this.object.ip_address}
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">Page</td>
                      <td>
                        ${this.object.page}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ` : html`
                <i>User is not part of an organisation (B2C customer)</i>
              `}
            </section>
          </flex-item>
        </flex-container>

        ${this.renderPrograms()}
        ${this.renderQuestionnaires()}
      `;
    }
  }
);
