import {AuthenticatedMixin, PWAPage, RoleMixin} from "../../shared/pwa-page";
import {Session} from "../../shared/session";
import {html} from "lit";
import {repeat} from "lit/directives/repeat.js";

import "./questionnaire-view";
import "./questionnaire-destroy";
import "./questionnaire-category-list";
import "./question-list";
import "./question-edit";
import "./question-category-list";
import "./question-category-edit";
import "./result-profile-list";
import "./result-profile-edit";
import "./result-profile-text-list";
import "./result-profile-text-edit";
import "./result-profile-text-destroy";

export const links = [{
  name: 'Questionnaire Categories',
  link: '/questionnaires/categories',
  icon: 'document',
  color: 'light-green',
}, {
  name: 'Questions',
  link: '/questionnaires/questions',
  icon: 'document',
  color: 'red',
}, {
  name: 'Question Categories',
  link: '/questionnaires/question_categories',
  icon: 'document',
  color: 'yellow',
}, {
  name: 'Result Profiles',
  link: '/questionnaires/result_profiles',
  icon: 'people',
  color: 'light-blue',
}];

customElements.define(
  "page-questionnaire",
  class PageQuestionnaire extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AuthenticatedMixin(PWAPage)) {
    render() {
      return html`
        <page-header>
          <div class="flex-wrapper">
            <page-logo>
              <a href="/">
                <figure>
                  <img
                    src="/assets/img/qogni-logo.svg"
                    alt="Qogni Logo"
                  />
                </figure>
              </a>
            </page-logo>

            <div class="info">
              <h1>Questionnaires</h1>
              <p>Questionnaire & Question Management</p>
            </div>

            <nav>
              <ul>
                ${repeat(links, (l) => html`
                  <li>
                    <a href="${l.link}" class=""> <!-- active -->
                      ${l.name}
                    </a>
                  </li>
                `)}
              </ul>
            </nav>

            <account-link>
              <a class="not-anonymous" href="/account">
                <figure>
                  <img
                    src="${app.session?.user?.profile_img_url ||
                    "/assets/img/profile-picture.webp"}"
                    alt="User profile picture"
                  />

                  <svg-icon
                    color="white"
                    style="--icon-size: 11px"
                    icon="bell"
                  ></svg-icon>
                </figure>

                <strong>${app.session?.user?.firstname || "Anonymous"}</strong>
              </a>
            </account-link>
          </div>
        </page-header>

        ${repeat(links, (l) => html`
          <link-card>
            <a href="${l.link}" class="card-link ${l.color}">
              <figure>
                <svg-icon
                  size="100%"
                  style="--icon-size: 11px"
                  icon="${l.icon}"
                ></svg-icon>
              </figure>

              <span>
                ${l.name}
              </span>
            </a>
          </link-card>
        `)}
      `;
    }
  }
);
