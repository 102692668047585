import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage, AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {FeedbackDomain} from "../../domain/feedback-domain";

customElements.define(
  "page-feedback-destroy",
  class PageFeedbackDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
    #domain;
    title = 'Feedback';
    redirect() {
      window.location.href = '/feedback';
    }

    constructor() {
      super(new FeedbackDomain());
    }
  }
);
