import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage, AdminListPage, AdminPage, AdminViewPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {FeedbackDomain} from "../../domain/feedback-domain";
import {html, nothing} from "lit";

customElements.define(
  "page-feedback-view",
  class PageFeedbackView extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminViewPage) {
    constructor() {
      super(new FeedbackDomain());
    }

    renderDetail(obj) {
      return html`
        <section class="card">
          <flex-container breakpoint="tiny">
            <flex-item>
              <a href="javascript:history.back()" class="button round tiny">Back</a>
              ${this.object.deleted_at ? nothing : html`
                <a href="/feedback/${this.object.id}/destroy" class="button round tiny red">Delete</a>
              `}
            </flex-item>
            <flex-item>
              <h1>Feedback ${this.object.user ? html`${this.object.user.firstname} ${this.object.user.lastname}` : nothing}</h1>
            </flex-item>
          </flex-container>
        </section>

        <flex-container breakpoint="normal">
          <flex-item class="col-6">
            <section class="card">
              <h3>User</h3>
              ${this.object.user ? html`
                <div class="data-table">
                  <table>
                    <tbody>
                    <tr>
                      <td class="bold">Image</td>
                      <td>
                        <img alt="profile picture"
                             src="${this.object.user.profile_img_url ?? '/assets/img/profile-picture.webp'}"
                             loading="lazy" class="rounded" style="height: 50px;" />
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">Name</td>
                      <td>
                        ${this.object.user.firstname} ${this.object.user.lastname}
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">Email</td>
                      <td>
                        ${this.object.user.email}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ` : html`User not known (anymore)`}
            </section>
          </flex-item>

          <flex-item class="col-6">
            <section class="card">
              <h3>Browser</h3>
              <div class="data-table">
                <table>
                  <tbody>
                  <tr>
                    <td class="bold">User-Agent</td>
                    <td>
                      ${this.object.user_agent}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">IP-address</td>
                    <td>
                      ${this.object.ip_address}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">Page</td>
                    <td>
                      ${this.object.page}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </flex-item>
        </flex-container>

        <section class="card">
          <h3>
            Comment, reported at: ${(new Date(this.object.created_at)).format({
             mode: 'absolute'
            })}
          </h3>
          <p>
            <i>
              ${this.object.comment}
            </i>
          </p>
        </section>

        ${this.object.deleted_at ? html`
          <section class="card red">
            Marked as completed at: ${(new Date(this.object.deleted_at)).format({
              mode: 'absolute'
            })}
          </section>
        ` : nothing}
      `;
    }
  }
);
