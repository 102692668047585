import {AuthenticatedMixin, PWAPage, RoleMixin} from "../../shared/pwa-page";
import {Session} from "../../shared/session";
import {html} from "lit";

import "./interest-topic-list";
import "./interest-topic-edit";
import "./tag-list";
import "./tag-edit";
import "./glossary-list";
import "./glossary-edit";
import "./glossary-destroy";

customElements.define(
  "page-app",
  class PageApp extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AuthenticatedMixin(PWAPage)) {
    render() {
      return html`
        <callout-card
          type="warning"
          icon="warning"
          content="Be careful adjusting data here, it could affect the App's running from this data sources and could change behaviour of the users and algo's.">
        </callout-card>

        <link-card>
          <a href="/app/interest-topics" class="card-link light-green">
            <figure>
              <svg-icon
                size="100%"
                style="--icon-size: 11px"
                icon="document"
              ></svg-icon>
            </figure>

            <span>Interest Topics</span>
          </a>
        </link-card>

        <link-card>
          <a href="/app/tags" class="card-link yellow">
            <figure>
              <svg-icon
                size="100%"
                style="--icon-size: 11px"
                icon="document"
              ></svg-icon>
            </figure>

            <span>Tags</span>
          </a>
        </link-card>

        <link-card>
          <a href="/app/glossary-definitions" class="card-link light-blue">
            <figure>
              <svg-icon
                size="100%"
                style="--icon-size: 11px"
                icon="document"
              ></svg-icon>
            </figure>

            <span>Glossary Definitions</span>
          </a>
        </link-card>
      `;
    }
  }
);
