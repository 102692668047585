import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-question-category-edit",
  class PageQuestionCategoryEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    constructor() {
      super(new GenericDomain('/question_categories'));
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          readonly: true,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: false,
          type: String,
          expanded: true,
          markdown: true,
        }, new TagField({
          object: this.object,
          onChange: (e) => {
            if (! this.object) this.object = {tags: []};
            this.object.tags = e.detail.tags;
            this.requestUpdate();
          },
        })
      ];
    }
  }
);
