import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { TagAutocomplete } from "../../shared/autocompletes";
import { createRef } from "lit/directives/ref.js";

customElements.define(
  "page-tag-edit",
  class PageTagEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    title = 'Tag';
    #parentRef = createRef();

    get fields() {
      const tagAutocomplete = new TagAutocomplete();
      tagAutocomplete.addEventListener('selected', this.selectedParent.bind(this));

      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          disabled: !! this.object?.id,
          type: String
        }, {
          label: 'Slug',
          property: 'slug',
          required: false,
          disabled: !! this.object?.id,
          type: String
        }, {
          label: 'Parent',
          property: 'parent_id',
          ref: this.#parentRef,
          type: String,
          autoComplete: tagAutocomplete.autoComplete,
          required: false,
        }
      ];
    }

    constructor() {
      super(new GenericDomain('/tags'));
    }

    selectedParent(e) {
      this.#parentRef.value.value = e.detail.tag.id;
    }
  }
);
