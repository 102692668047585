import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";
import { Task } from "@qogni-technologies/design-system/src/shared/task";

customElements.define(
  "page-organisation-location-edit",
  class PageOrganisationLocationEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    title = 'Location';
    #organisationDomain;

    static get properties() {
      return {
        ...super.properties,
        organisationId: { type: String, attribute: 'organisation-id' },
        organisation: { type: Object },
      };
    }

    constructor() {
      super(new GenericDomain());
      this.#organisationDomain = new GenericDomain(`/organisations`);
    }

    async connectedCallback() {
      super.connectedCallback();
      this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations`);
    }

    async fetch(options = {}) {
      const fetchOrganisation = Task.run(async () => {
        this.organisation = (await this.#organisationDomain.show(this.organisationId))?.data;
      });

      return Promise.all([
        super.fetch(options),
        fetchOrganisation,
      ]);
    }

    get before() {
      return html`
        <section class="card">
          <p>
            <strong>
              Location will be created in the following structure:
            </strong>
          </p>
          <table class="w-100">
            <tr>
              <td class="bold"><strong>
                Organisation
              </strong></td>
              <td>
                ${this.organisation?.name}
              </td>
            </tr>
          </table>
        </section>
      `;
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String,
        }, {
          label: 'Address (line 1)',
          property: 'address_line_1',
          required: false,
          type: String,
        }, {
          label: 'Address (line 2)',
          property: 'address_line_2',
          required: false,
          type: String,
        }, {
          label: 'City',
          property: 'city',
          required: false,
          type: String,
        }, {
          label: 'State',
          property: 'state',
          required: false,
          type: String,
        }, {
          label: 'Postal Code',
          property: 'postal_code',
          required: false,
          type: String,
        }, {
          label: 'Country',
          property: 'country',
          required: false,
          type: String,
        },
      ];
    }
  }
);
