import { AppDomainHandler } from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class StatisticsDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async totalUsers() {
    return (await this.#api.getData('/statistics/users/total')).data;
  }
  async activeUsers() {
    return (await this.#api.getData('/statistics/users/active')).data;
  }
  async sales() {
    return (await this.#api.getData('/statistics/orders/sales')).data;
  }
}
