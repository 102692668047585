import {html} from "lit";
import {AuthenticatedMixin, PWAPage} from "../shared/pwa-page";

customElements.define(
  "page-questionnaires",
  class PageQuestionnaires extends AuthenticatedMixin(PWAPage) {
    render() {
      return html`
        <page-header>
          <div class="flex-wrapper">
            <page-logo>
              <a href="/">
                <figure>
                  <img
                    src="/assets/img/qogni-logo.svg"
                    alt="Qogni Logo"
                  />
                </figure>
              </a>
            </page-logo>

            <div class="info">
              <h1>Questionnaires</h1>
              <p>Under development</p>
            </div>

            <nav>
              <ul>
                <li>
                  <a href="/" class="active">
                    Dummy link
                  </a>
                </li>

                <li>
                  <a href="/">
                    Dummy link
                  </a>
                </li>

                <li>
                  <a href="/">
                    Dummy link
                  </a>
                </li>
              </ul>
            </nav>

            <account-link>
              <a class="not-anonymous" href="/account">
                <figure>
                  <img
                    src="${app.session?.user?.profile_img_url ||
                    "/assets/img/profile-picture.webp"}"
                    alt="User profile picture"
                  />

                  <svg-icon
                    color="white"
                    style="--icon-size: 11px"
                    icon="bell"
                  ></svg-icon>
                </figure>

                <strong>${app.session?.user?.firstname || "Anonymous"}</strong>
              </a>
            </account-link>
          </div>
        </page-header>

        <section class="card col-4">
          <badge-tag class="red mb-tiny">col-4</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>

        <section class="card col-4">
          <badge-tag class="red mb-tiny">col-4</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>

        <section class="card col-4">
          <badge-tag class="red mb-tiny">col-4</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>

        <section class="card col-6">
          <badge-tag class="red mb-tiny">col-6</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>

        <section class="card col-6">
          <badge-tag class="red mb-tiny">col-6</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>

        <section class="card">
          <badge-tag class="red mb-tiny">col-12</badge-tag>
          <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi aspernatur doloremque error officia officiis quam quos ratione rem reprehenderit.</h3>
        </section>
      `;
    }
  }
);
