import {config} from "../app-config";

/**
 * Encapsulates fetch() functionality
 * @event beforeRequest dispatched before fetching
 * @event afterResponse dispatched when response is fetched, but before returning the results.
 */
export class ApiRequest extends EventTarget {
  #interceptors = [];

  static jwt = null;

  constructor(baseUrl, defaultHeaders = {}) {
    super();
    this.baseUrl = baseUrl;
    this.defaultHeaders = defaultHeaders;
  }

  static factory() {
    return new this(config.baseUrl);
  }

  async #execute(localUrl, options = {}) {
    try {
      if (ApiRequest.jwt) {
        if (!options.headers) {
          options.headers = {}
        }
        options.headers['Authorization'] = `Bearer ${ApiRequest.jwt}`;
      }

      const mergedOptions = {
        method: "GET",
        ...options,
        headers: {...this.defaultHeaders, ...options.headers}
      };

      const url = `${this.baseUrl}${localUrl}`;

      this.#dispatch("beforeRequest", {
        request: {
          url: url,
          options: mergedOptions
        }
      });

      const response = await fetch(url, mergedOptions);

      this.#dispatch("afterResponse", {
        request: {
          url: url,
          options: mergedOptions
        },
        response: response
      });

      if (!response.ok) {
        const error = new Error(`HTTP error! Status: ${response.status}`);
        error.response = response;
        try {
          error.errorData = await response.json();
        } catch {
          // ignore
        }
        // noinspection ExceptionCaughtLocallyJS
        throw error;
      }
      return await response.json();
    } catch (error) {
      // Handle network errors or other issues
      console.error("Fetch error:", error);
      throw error;
    }
  }

  #dispatch(eventName, data) {
    return this.dispatchEvent(
      new CustomEvent(eventName, {
        detail: data
      })
    );
  }

  addInterceptor(interceptor) {
    this.#interceptors.push(interceptor);
  }

  on(eventName, func) {
    this.addEventListener(eventName, func);
    return this;
  }

  /**
   * GET data resource.
   * @param {string} url Path
   * @param {any} options Any options given to the request method
   * @returns any
   */
  async getData(url, options = {}) {
    return this.#execute(url, {
      ...options,
      method: "GET"
    });
  }

  async postData(url, data = {}, options = {}) {
    return this.#execute(url, {
      ...options,
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async putData(url, data, options = {}) {
    return this.#execute(url, {
      ...options,
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  /**
   *
   * @param {string} method
   * @param {string} url
   * @param {FormData} data
   * @param options
   * @returns {Promise<any|undefined>}
   */
  async uploadFile(method, url, data, options = {}) {
    return this.#execute(url, {
      ...options,
      method,
      headers: {"Accept": "application/json"},
      body: data
    });
  }

  async patchData(url, data, options = {}) {
    return this.#execute(url, {
      ...options,
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async deleteData(url, data = {}, options = {}) {
    return this.#execute(url, {
      ...options,
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }
}
