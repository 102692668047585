import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireCategoryDomain} from "../../domain/questionnaire-category-domain";
import {ResultProfileDomain} from "../../domain/result-profile-domain";
import {html} from "lit";

customElements.define(
  "page-result-profile-list",
  class PageResultProfileList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = true;
    searchable = true;
    title = 'Result Profiles';

    newUrl() {
      return `/questionnaires/result_profiles/new`;
    }
    editUrl(obj) {
      return `/questionnaires/result_profiles/${obj.id}`;
    }

    constructor() {
      super(new ResultProfileDomain());
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true},
        {name: 'Slug', field: 'slug', sortable: true},
        {name: 'Neurotransmitters', field: null, render(obj) {
          const entries = [];
          if (obj.dopamine) entries.push('Dopamine');
          if (obj.acetylcholine) entries.push('Acetylcholine');
          if (obj.gaba) entries.push('GABA');
          if (obj.serotonin) entries.push('Serotonin');

          return html`
            <small>
              ${entries.join(', ')}
            </small>
          `;
        }},
        {name: 'Type', field: 'type', sortable: true, render: (obj) => html`<strong>${obj.type.toUpperCase()}</strong>`},
      ];
    }
  }
);
