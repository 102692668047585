import "./organisation-category-list";
import "./organisation-category-edit";
import "./organisation-type-list";
import "./organisation-type-edit";
import "./organisation-list";
import "./organisation-edit";
import "./organisation-view";
import "./user-list";
import "./user-view";
import "./user-edit";
import "./user-destroy";
import "./role-list";
import "./role-edit";
import "./role-destroy";

import { AdminIndexPage } from "../../shared/admin";

export const links = [{
  name: 'Roles',
  link: '/tenants/roles',
  icon: 'account-add',
}, {
  name: 'Users',
  link: '/tenants/users',
  icon: 'account',
}, {
  name: 'Organisations',
  link: '/tenants/organisations',
  icon: 'people',
}, {
  name: 'Organisation Types',
  link: '/tenants/organisation_types',
}, {
  name: 'Organisation Categories',
  link: '/tenants/organisation_categories',
}];

export class PageTenants extends AdminIndexPage {
  constructor() {
    super(links);
  }
}

customElements.define(
  "page-tenants",
  PageTenants,
);
