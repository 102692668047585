import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RoleDomain} from "../../domain/role-domain";
import {html} from "lit";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-organisation-edit",
  class PageOrganisationEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    #organisationTypeDomain;
    #organisationCategoryDomain;

    constructor() {
      super(new GenericDomain('/organisations'));
      this.#organisationTypeDomain = new GenericDomain('/organisation_types');
      this.#organisationCategoryDomain = new GenericDomain('/organisation_categories');
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String,
        }, {
          label: 'Category',
          property: 'organisation_category_id',
          required: true,
          type: 'Select',
          choices: async () => {
            const response = await this.#organisationCategoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            })
          }
        }, {
          label: 'Type',
          property: 'organisation_type_id',
          required: true,
          type: 'Select',
          choices: async () => {
            const response = await this.#organisationTypeDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            })
          }
        }, {
          label: 'Description',
          property: 'description',
          required: false,
          type: String,
          expanded: true,
        }, {
          label: 'Phone',
          property: 'phone',
          type: String,
        }, {
          label: 'Address (line 1)',
          property: 'address_line_1',
          required: false,
          type: String,
        }, {
          label: 'Address (line 2)',
          property: 'address_line_2',
          required: false,
          type: String,
        }, {
          label: 'City',
          property: 'city',
          required: false,
          type: String,
        }, {
          label: 'State',
          property: 'state',
          required: false,
          type: String,
        }, {
          label: 'Postal Code',
          property: 'postal_code',
          required: false,
          type: String,
        }, {
          label: 'Country',
          property: 'country',
          required: false,
          type: 'Select',
          choices: async () => {
            const response = await this.domain.api.getData('/utils/countries');
            return response.data.map((c) => {
              return {
                name: c.name,
                value: c.alpha2
              };
            });
          }
        }, {
          label: 'VAT-number',
          property: 'vat_number',
          required: false,
          type: String,
          after: (f, v) => {
            console.log(this);
            if (this.object && this.object.vat_number) {
              return html`
                ${this.object.vat_number_verified_at ? html`
                  <callout-card type="success" title="Verified at ${new Date(this.object.vat_number_verified_at).format({mode: 'format'})}">
                  </callout-card>
                ` : html`
                  <callout-card type="warning" title="Not verified (yet)">
                  </callout-card>
                `}
              `;
            }
          }
        }
      ];
    }
  }
);
