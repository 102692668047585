import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";

customElements.define(
  "page-glossary-definition-list",
  class PageGlossaryDefinitionList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = true;
    title = 'Glossary Definitions';

    constructor() {
      super(new GenericDomain('/glossary_definitions'));
    }

    editUrl(obj) {
      return `/app/glossary-definitions/${obj.id}`;
    }

    destroyUrl(obj) {
      return `/app/glossary-definitions/${obj.id}/destroy`;
    }

    newUrl() {
      return `/app/glossary-definitions/new`;
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true},
        {name: 'Slug', field: 'slug', sortable: true},
        {name: 'URL', field: 'url', sortable: true},
        {name: 'Tags', field: null, render: (obj) => html`<small>${obj.tags ? html`
          ${repeat(obj.tags, (t) => html`${t.name},`)}
        ` : html`-`}</small>`},
      ];
    }
  }
);
