import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-program-list",
  class PageProgramList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = false;
    searchable = true;
    title = 'Program List';

    editUrl(obj) {
      return `/programs/${obj.id}`;
    }

    constructor() {
      super(new GenericDomain('/programs'));
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, searchable: true},
      ];
    }
  }
);
