import { GenericDomain } from "../../domain/generic-domain";
import { AdminEditPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

customElements.define(
  "page-option-category-edit",
  class OptionCategoryEdit extends RoleMixin(
    Session.ROLE_QOGNI_ADMIN,
    AdminEditPage
  ) {
    title = "Option Category";

    constructor() {
      super(new GenericDomain('/option_categories'));
    }

    get fields() {
      return [
        {
          label: "Name",
          property: "name",
          required: true,
          type: String,
        },
      ];
    }
  }
);
