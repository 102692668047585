import {
  EventTargetMixin,
  createProxy,
  debounce,
  isMobile,
  parseHTML,
} from "@qogni-technologies/design-system/src/shared/common";
import {config} from "../app-config";
import {Session} from "./session";
import {html} from "lit";

/**
 * Qogni App
 */
customElements.define(
  "qogni-app",
  class QogniApp extends EventTargetMixin(HTMLElement) {
    #session;
    #state; // eslint-disable-line no-unused-private-class-members
    #config; // eslint-disable-line no-unused-private-class-members

    #toaster;
    #initialized = false;

    router = null;

    get session() {
      return this.#session;
    }

    constructor() {
      super();
      this.#session = Session.factory();

      this.#state = createProxy(this, {});
      this.#config = config;
      window.app = this;

      this.#session.addEventListener("authenticated", () => {
        setTimeout(() => {
          app.addToastMessage(
            `Welcome ${this.session.user?.firstname || ""} to Qogni!`
          );
        }, 500);
      });
    }

    async connectedCallback() {
      await this.initialize();
    }

    async initialize() {
      if (!this.session.initialized) {
        await this.session.init();
        const footer = parseHTML(/*html*/ `<footer>
          <bottom-bar appearance="global" class="not-anonymous"></bottom-bar>
        </footer>`)[0];

        this.insertAdjacentElement("beforeend", footer);

        const aside = parseHTML(/*html*/ `<aside>
          <side-bar appearance="global" class="not-anonymous"></side-bar>
        </aside>`)[0];

        this.insertAdjacentElement("beforeend", aside);

        this.#toaster = parseHTML(/*html*/ `
            <message-toaster></message-toaster>
          `)[0];

        this.insertAdjacentElement("beforeend", this.#toaster);

        document.querySelector("header").addEventListener("mousedown", () => {
          location.href = "/";
        });

        aside.querySelector("side-bar").items = this.getButtons(true);
        footer.querySelector("bottom-bar").items = this.getButtons(false);
      }

      this.initView();

      // react to 'open' class on side-bar
      this.monitorSideBarOpen(() => {
        this.updateDocumentClasses();
      });

      // qogni-app throttles resize and fires own event
      app.on("throttled-resize", () => {
        this.updateDocumentClasses();
      });

      // Update account-link details.
      this.session.on('profile-updated', (e) => {
        this.#updateUserDetails(e.detail.user);
      });
      this.session.on('authenticated', (e) => {
        this.#updateUserDetails(this.session.user);
      });

      this.#initialized = true;

      // Refresh user entity.
      setTimeout(() => {
        this.updateDocumentClasses();

        if (this.session.isAuthenticated) {
          this.session.refreshUser();
        }
      }, 250);
    }

    get initialized() {
      return this.#initialized;
    }

    get activeRoute() {
      return this.router?.activeRoute;
    }

    hideSidebar() {
      this.querySelector('aside > side-bar').display = 'none';
    }

    #updateUserDetails(u) {
      if (this.querySelector('account-link > a > strong')) {
        this.querySelector('account-link > a > strong').innerText = u.firstname || 'Anonymous';
        this.querySelector('account-link img').src = u.profile_img_url || '/assets/img/profiles/profile-picture.webp';
      }
    }

    monitorSideBarOpen(func) {
      const me = this;
      me.aside = this.querySelector("aside");

      const config = {
        attributes: true,
        childList: true,
        characterData: true, // This is the key option to observe text changes
        subtree: true, // Observe changes in the descendants of the target node
      };
      // Callback function to execute when mutations are observed
      const callback = function (mutationsList) {
        for (let mutation of mutationsList) {
          if (mutation.type === "attributes") {
            func(me.aside.classList.contains("open"));
          }
        }
      };
      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);
      // Start observing the target node for configured mutations
      observer.observe(this.aside, config);
    }

    // classes for correct positioning on html element
    updateDocumentClasses() {
      const open = this.aside.classList.contains("open");
      const asideVisible = open || window.innerWidth >= 601;
      this.sideBar = this.aside.querySelector("side-bar");
      this.sideBar.classList.toggle("open", asideVisible);
    }

    get isOnboarded() {
      try {
        return this.session?.isOnboarded;
      } catch {
        return false;
      }
    }

    getButtons(isSideBar) {
      const items = this.getGlobalMenuButtons();

      // filter and sort menu items depending on type
      return !isSideBar
        ? items
          .filter((i) => {
            return i.bottomMenuIndex;
          })
          .sort((a, b) => {
            return a.bottomMenuIndex > b.bottomMenuIndex ? 1 : -1;
          })
        : items.filter((i) => {
          return i.sideMenuIndex !== 0 && i.icon;
        });
    }

    get loader() {
      return html`
        <div class="fill skeleton">Loading...</div>`;
    }

    initView() {
      /**
       * document-level classes for mobile and landscape orientation
       */
      const rootElement = window.document.documentElement;
      const getOrientation = () => {
        return window.innerWidth / innerHeight > 1 ? "landscape" : "portrait";
      };
      let lastOrientation = getOrientation();
      const resize = () => {
        rootElement.style.setProperty(
          "--viewport-height",
          `${window.innerHeight}px`
        );
        this.fire("throttled-resize");

        const orientation = getOrientation();
        rootElement.classList.toggle(
          "vw-landscape",
          orientation === "landscape"
        );

        rootElement.classList.toggle("is-mobile", isMobile());
        if (lastOrientation !== orientation)
          window.dispatchEvent(
            new CustomEvent("orientation-change", {
              detail: {
                orientation: orientation,
              },
            })
          );

        lastOrientation = orientation;
      };

      window.addEventListener("resize", debounce(resize));
      resize();
    }

    /**
     * Add a toast message
     * @param {*} msg
     * @param {*} options
     */
    addToastMessage(msg, options = {type: "info"}) {
      const ev = new CustomEvent("notification", {
        detail: {
          text: msg,
          ...(options || {}),
          type: options?.type || "info",
        },
      });
      window.dispatchEvent(ev);
    }

    getGlobalMenuButtons() {
      const createPath = (id) => {
          if (id.indexOf("/") !== -1) return id;
          return `/${id}`;
        },
        items = [];
      items.push({
        id: "menu",
        icon: "hamburger",
        bottomMenuIndex: 1,
        sideMenuIndex: 0,
      });
      for (const [key, value] of Object.entries(config.pages)) {
        if (! Object.prototype.hasOwnProperty.call(value, 'active') || typeof value.active !== 'function') {
          value.active = (name, self) => {
            if (name === undefined) return false;
            if (self.route) {
              if (typeof self.route !== 'object') return false;
              if (typeof Object.values(self.route)[0] !== 'function') return false;
              return Object.values(self.route)[0].name === name;
            }
            return name === self.id;
          }
        }
        items.push({
          id: key,
          path: createPath(key),
          ...value,
        });
      }
      return items;
    }
  }
);
