import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RoleDomain} from "../../domain/role-domain";
import {html} from "lit";

customElements.define(
  "page-role-edit",
  class PageRoleEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    constructor() {
      super(new RoleDomain());
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String,
        }, {
          label: 'Description',
          property: 'description',
          required: true,
          type: String,
          expanded: true,
        }, {
          label: 'Badge',
          property: 'badge',
          type: 'Checkbox',
        }
      ];
    }

    renderFields() {
      if (this.object?.internal) return html`
        <section class="card red">
          <h3>This role is internal and can't be changed</h3>
        </section>
      `;
      return super.renderFields();
    }
  }
);
