import { RoleMixin } from "../../shared/pwa-page";
import { AdminDestroyPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-glossary-definition-destroy",
  class PageGlossaryDefinitionDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
    title = 'Glossary Definition';
    redirect = '/app/glossary-definitions';

    constructor() {
      super(new GenericDomain('/glossary_definitions'));
    }
  }
);
