import {RoleMixin} from "../../shared/pwa-page";
import { AdminDestroyPage, AdminEditPage } from "../../shared/admin";
import {Session} from "../../shared/session";
import { ChannelAdminDomain } from "../../domain/channel-admin-domain";

customElements.define(
  "page-channel-admin-destroy",
  class PageChannelAdminDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
    title = 'Channel Admin';

    static get properties() {
      return {
        channelId: { type: String, attribute: 'channel-id' },
      };
    }
    constructor() {
      super(new ChannelAdminDomain());
    }
    connectedCallback() {
      this.domain.channelId = this.channelId;
      super.connectedCallback();
    }
  }
);
