import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";
import { ResultProfileTextDomain } from "../../domain/result-profile-text-domain";
import { ChannelAdminDomain } from "../../domain/channel-admin-domain";

customElements.define(
  "page-channel-admin-list",
  class PageChannelAdminList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    title = 'Channel';

    static get properties() {
      return {
        channelId: { type: String, attribute: 'channel-id' },
      };
    }

    newUrl() {
      return `/social/channels/${this.channelId}/admins/new`;
    }
    editUrl(obj) {
      return `/social/channels/${this.channelId}/admins/${obj.user_id}`;
    }
    destroyUrl(obj) {
      return `/social/channels/${this.channelId}/admins/${obj.user_id}/destroy`;
    }

    get columns() {
      return [
        {name: 'Name', field: 'user_id', sortable: true, filterable: false, searchable: false, render: obj => {
          return obj.user?.firstname + ' ' + obj.user?.lastname;
        }},
        {name: 'Role', field: 'role', sortable: true, filterable: false, searchable: false},
        {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
      ];
    }
    constructor() {
      super(new ChannelAdminDomain());
    }

    connectedCallback() {
      this.domain.channelId = this.channelId;
      super.connectedCallback();
    }
  }
);
