import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";
import { html, nothing } from "lit";

customElements.define(
  "page-tag-list",
  class PageTagList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    title = 'Tags';

    constructor() {
      super(new GenericDomain('/tags'));
    }

    get fetchOptions() {
      return {with_counts: true};
    }

    editUrl(obj) {
      return `/app/tags/${obj.id}`;
    }

    destroyUrl(obj) {
      return `/app/tags/${obj.id}/destroy`;
    }

    newUrl() {
      return `/app/tags/new`;
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false, render: obj => {
          return html`${obj.deleted_at ? html`&dagger; ` : nothing}${obj.name}`;
        }},
        {name: 'Slug', field: 'slug', sortable: true},
        {name: 'Parent', field: 'parent_id', sortable: true, render: (obj) => html`
          <a href="/app/tags/${obj.parent_id}">${obj.parent?.name ?? obj.parent_id}</a>
        `},
        {name: 'Number of objects', field: 'taggable_count', sortable: true},
        {name: 'Deleted', field: 'deleted_at', sortable: true, render: dateTimeRenderer},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
