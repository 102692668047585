import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {html} from "lit";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-question-category-list",
  class PageQuestionCategoryList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = false;
    searchable = true;
    title = 'Question Categories';

    constructor() {
      super(new GenericDomain('/question_categories'));
    }

    editUrl(obj) {
      return `/questionnaires/question_categories/${obj.id}`;
    }

    get columns() {
      return [
        {name: 'ID', field: 'id', classList: {small: true}},
        {name: 'Name', field: 'name', sortable: true},
        {name: 'Description', field: 'description', sortable: true, render: (obj) => html`<small>${obj.description}</small>`},
      ];
    }
  }
);
