import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";
import { html, nothing } from "lit";

customElements.define(
  "page-channel-list",
  class PageChannelList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = true;
    canView = true;
    viewLinkText = 'Admins';
    title = 'Channels';

    get fetchOptions() {
      return {
        with_trashed: true
      };
    };

    constructor() {
      super(new GenericDomain('/channels'));
    }

    editUrl(obj) {
      return `/social/channels/${obj.id}`;
    }

    destroyUrl(obj) {
      return `/social/channels/${obj.id}/destroy`;
    }

    viewUrl(obj) {
      return `/social/channels/${obj.id}/admins`;
    }

    newUrl() {
      return `/social/channels/new`;
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false, render: obj => {
          return html`${obj.deleted_at ? html`&dagger; ` : nothing}${obj.name}`;
        }},
        {name: 'Slug', field: 'slug', sortable: true},
        {name: 'Deleted', field: 'deleted_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
        {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
      ];
    }
  }
);
