import {html, LitElement} from "lit";
import {repeat} from "lit/directives/repeat.js";
import {ButtonBarAppearance} from "@qogni-technologies/design-system/src/shared/common";

/**
 * Customizable button bar component
 */
export class ButtonBar extends LitElement {
  static get properties() {
    return {
      items: {type: Object, attribute: false},
      class: {type: String, attribute: true},
      global: {type: Boolean, attribute: true},
      appearance: {type: ButtonBarAppearance, attribute: true},
    };
  }

  enable(buttonId, enabled) {
    const button = this.renderRoot.querySelector(`[data-id='${buttonId}']`);
    if (button) button.classList.toggle("disabled", !enabled);
  }

  active(buttonId, enabled) {
    const button = this.renderRoot.querySelector(`[data-id='${buttonId}']`);
    if (button) button.classList.toggle("active", !enabled);
  }

  highlight(buttonId) {
    const button = this.renderRoot.querySelector(`[data-id='${buttonId}']`);
    if (button) {
      button.classList.toggle("highlighted", true);
      setTimeout(() => {
        button.classList.toggle("highlighted", false);
      }, 1000);
    }
  }

  connectedCallback() {
    super.connectedCallback();

    app.on('route-change', () => {
      this.requestUpdate();
    });
  }

  render() {
    if (!this.items) return html``;

    const cls = (button) => {
      let classNames = (button.class || "") + (button.primary ? " primary" : "")
      if (button.bottomMenuIndex > 0) {
        classNames += " in-bottom";
      }

      if (Object.prototype.hasOwnProperty.call(button, 'active')
        && typeof button.active === "function"
        && button.active(app.router.activeRoute, button)) {
        classNames += " active";
      }
      return classNames.trim();
    };

    return html`
      <slot name="before"></slot>
      <nav @click=${this.navClick} class="${this.class}">
        ${repeat(
          this.items,
          (button) => button.id,
          (button) => html`
            <a
              title="${button.name}"
              data-id="${button.id}"
              class="${cls(button)}"
              href="${button.path}"
              @click=${button.click}
            >
              <figure>
                <svg-icon size="100%" icon="${button.icon}"></svg-icon>
              </figure>
              <span data-text>${button.name}</span>
            </a>
          `
        )}
      </nav>
      <slot name="after"></slot>
    `;
  }
}
