import {html, LitElement} from "lit";
import {Router} from "./router";
import {config} from "../app-config";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {until} from "lit/directives/until.js";
import {EventTargetMixin} from "@qogni-technologies/design-system/src/shared/common";

customElements.define(
  "spa-route",

  class PWARenderRoute extends EventTargetMixin(LitElement) {
    static get properties() {
      return {
        content: {type: Object},
      };
    }

    // use Light DOM
    createRenderRoot() {
      return this;
    }

    constructor() {
      super();

      const createRoute = (key) => {
        const template = unsafeHTML(`<page-${key}></page-${key}>`);
        const closure = (routeData) => html`${template}`; // eslint-disable-line no-unused-vars

        // Set the function name to the key, used for menu-matching.
        Object.defineProperty(closure, 'name', {value: key});
        return closure;
      };

      // get routes from page config.
      const getRoutes = (pages) => {
        const routes = {};
        Object.keys(pages).forEach((key) => {
          const route = pages[key].route;
          if (route) {
            const k = Object.keys(route)[0];
            routes[k] = route[k];
          } else {
            routes[key] = createRoute(key);
          }
        });
        return routes;
      };

      this.router = new Router(
        this,
        getRoutes(config.pages),
        html`<http-404></http-404>`,
        app.loader
      );

      // Expose the router to the window.
      window.router = this.router;
      if (Object.prototype.hasOwnProperty.call(window, 'app')) {
        window.app.router = this.router;
      }
    }

    /**
     * Called from router
     * @param {Object} content - route data to render
     */
    setRouteContent(content) {
      this.content = content;
      this.requestUpdate();
    }

    render() {
      if (!this.content) this.getContent();

      return html`${until(this.content, app.loader)}`;
    }

    /**
     * Called on first page request.
     * On route changes (via router), setRouteContent()
     * is called from router, which forces re-render.
     */
    getContent() {
      const route = this.router.matchRoute();
      this.content = new Promise((resolve) => {
        app.session.init().then(() => {
          resolve(route);
        });
      });
    }
  }
);
