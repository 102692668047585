import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-program-edit",
  class PageProgramEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    constructor() {
      super(new GenericDomain('/programs'));
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: true,
          type: String,
          markdown: true,
        },
      ];
    }
  }
);
