import {html} from "lit";
import {polyfillsLoaded} from "../polyfills/polyfillsLoader";
import {until} from "lit/directives/until.js";
import {startViewTransition} from "@qogni-technologies/design-system/src/shared/common";

export class Router {
  #host;
  routeMap = new Map();
  #activeRoute = null;

  constructor(host, routes, notFoundPage, loadingPage) {
    this.#host = host;
    this.loadingPage = loadingPage || html` <span>Loading...</span> `;
    this.ready = this.init(routes, notFoundPage); // Compile url patterns
  }

  async init(routes, notFoundPage) {
    const me = this;
    await polyfillsLoaded; //Make sure that polyfills are loaded before using URLPattern API

    if (routes)
      for (const r in routes) {
        this.routeMap.set(new URLPattern(r, window.location.origin), routes[r]);
      }

    this.routeMap.set(
      new URLPattern({
        pathname: "*",
        search: "*",
        baseURL: location.origin,
      }),
      notFoundPage || html``
    );

    navigation.addEventListener("navigate", async (event) => {
      const routeData = this.getRoute(event.destination.url);
      if (!routeData) return;

      if (Object.prototype.hasOwnProperty.call(routeData, 'name'))
        this.activeRoute = routeData.name;

      event.intercept({
        async handler() {
          startViewTransition(() => {
            me.#host.setRouteContent(routeData);

            // Scroll to top of page.
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'instant'
            });
          });
        },
      });
    });
  }

  get activeRoute() {
    return this.#activeRoute;
  }

  set activeRoute(routeName) {
    // Set Active route and fire event.
    const oldRoute = this.#activeRoute;
    this.#activeRoute = routeName;
    app.fire('route-change', {from: oldRoute, to: routeName});
  }

  getRoute(urlString) {
    try {
      const url = new URL(urlString, location);
      for (const [pattern, details] of this.routeMap) {
        const patternResult = pattern.exec(url);
        if (!patternResult) continue;
        const routeData = typeof details === "function" ? details(patternResult) : details;
        routeData['name'] = typeof details === "function" ? details.name : details + "";
        return routeData;
      }
    } catch (ex) {
      console.error("getRoute error: ", ex);
    }
  }

  async matchWhenReady() {
    await this.ready; // Wait for compiled patterns and polyfills (if needed)

    const url = window.location.href.split("?")[0];

    for (const [pattern, handler] of this.routeMap) {
      const patternResult = pattern.exec(url);
      if (!patternResult) continue;

      if (typeof handler === 'function')
        this.activeRoute = handler.name;

      return typeof handler === "function" ? handler(patternResult) : handler;
    }
  }

  matchRoute() {
    // Use until directive to show "Loading..." message while polyfills are being loaded.
    // https://lit.dev/docs/templates/directives/#until
    return until(this.matchWhenReady(), this.loadingPage);
  }
}
