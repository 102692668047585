import {html} from "lit";
import {AuthenticatedMixin, PWAPage, RoleMixin} from "../shared/pwa-page";
import {Session} from "../shared/session";
import {Task} from "@qogni-technologies/design-system/src/shared/task";
import {UserDomain} from "../domain/user-domain";
import {StatisticsDomain} from "../domain/statistics-domain";
import {QuestionnaireDomain} from "../domain/questionnaire-domain";

const pieChartOptions = {
  series: [44, 55, 13, 33],
  labels: ['GABA', 'Dopamin', 'Serotonin', 'Acethylcholine'],
  legend: {
    show: false
  },
  chart: {
    type: 'pie',
  },
  fill: {
    colors: ['#E54271', '#2D9DC9', '#209666', '#F2A74D']
  }
};

const pieChartJsonString = JSON.stringify(pieChartOptions);

const lineChartOptions = {
  series: [{
    name: "Users",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
  }],
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  colors: ['#2D9DC9'],
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  grid: {
    row: {
      colors: ['#F4F0EA', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
  }
};

const lineChartJsonString = JSON.stringify(lineChartOptions);

const areaChartOptions = {
  series: [{
    name: 'series1',
    data: [31, 40, 28, 51, 42, 109, 100]
  }, {
    name: 'series2',
    data: [11, 32, 45, 32, 34, 52, 41]
  }],
  chart: {
    height: 350,
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  colors: ['#209666', '#F2A74D'],
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    },
  },
};

const areaChartJsonString = JSON.stringify(areaChartOptions);

const columnChartOptions = {
  series: [{
    name: 'New users',
    data: [77, 44, 55, 57, 14, 56, 61, 58, 63, 60, 66, 12]
  }, {
    name: 'Existing users',
    data: [16, 13, 76, 85, 101, 98, 87, 105, 91, 114, 94, 12]
  }, {
    name: 'Potential increase',
    data: [12, 35, 28, 41, 36, 26, 45, 48, 52, 53, 41, 12]
  }],
  chart: {
    type: 'bar',
    height: 400
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded'
    },
  },
  xaxis: {
    categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev'],
  },
  yaxis: {
    title: {
      text: 'In thousands'
    }
  },
  fill: {
    colors: ['#E54271', '#2D9DC9', '#209666', '#F2A74D']
  },
};

const columnChartJsonString = JSON.stringify(columnChartOptions);

customElements.define(
  "page-home",
  class PageHome extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AuthenticatedMixin(PWAPage), '/enter') {
    #statDomain;
    #questionnaireDomain;
    #userDomain;

    static get properties() {
      return {
        totalUsers: { type: Number },
        newUsersThisWeek: { type: Number },
        newUsersLastWeek: { type: Number },
        activeUsers: { type: Number },
        salesThisMonth: { type: Number },
        salesLastMonth: { type: Number },
        activeUserList: { type: Array },
        recentQuestionnaires: { type: Array },
      }
    }

    constructor() {
      super();

      this.#statDomain = new StatisticsDomain();
      this.#userDomain = new UserDomain();
      this.#questionnaireDomain = new QuestionnaireDomain();
    }

    connectedCallback() {
      super.connectedCallback();

      this.fetch();
    }

    fetch() {
      Task.run(async() => {
        const response = await this.#statDomain.totalUsers();
        this.totalUsers = response.total_users;
        this.newUsersLastWeek = response.created_last_week;
        this.newUsersThisWeek = response.created_this_week;
      }, {global: false});
      Task.run(async() => {
        const response = await this.#statDomain.activeUsers();
        this.activeUsers = response.active_users;
      }, {global: false});
      Task.run(async() => {
        const response = await this.#statDomain.sales();
        this.salesThisMonth = response.sales_this_month;
        this.salesLastMonth = response.sales_last_month;
      }, {global: false});
      Task.run(async() => {
        const response = await this.#userDomain.list({per_page: 20, sort: 'last_online', direction: 'desc'});
        this.activeUserList = response.data.map((u) => {return {
          name: u.firstname + ' ' + u.lastname,
          email: u.email,
          online: u.last_online
        }});
      }, {global: false});
      Task.run(async() => {
        const response = await this.#questionnaireDomain.list({per_page: 20, sort: 'updated_at', direction: 'desc'});
        this.recentQuestionnaires = response.data.map((q) => {return {
          category: html`
            <a href="/questionnaires/${q.id}/view">
              ${q.questionnaire_category.name}
            </a>
          `,
          step: q.current_step,
          total: q.total,
          updated: q.updated_at,
        }});
      }, {global: false});
    }

    render() {
      return html`
        <page-header>
          <div class="flex-wrapper">
            <page-logo>
              <a href="/">
                <figure>
                  <img
                    src="/assets/img/qogni-logo.svg"
                    alt="Qogni Logo"
                  />
                </figure>
              </a>
            </page-logo>

            <div class="info">
              <h1>Home</h1>
              <p>Overview of the Qogni App.</p>
            </div>

            <nav></nav>
<!--            <nav>-->
<!--              <ul>-->
<!--                <li>-->
<!--                  <a href="/" class="active">-->
<!--                    Dummy link-->
<!--                  </a>-->
<!--                </li>-->

<!--                <li>-->
<!--                  <a href="/">-->
<!--                    Dummy link-->
<!--                  </a>-->
<!--                </li>-->

<!--                <li>-->
<!--                  <a href="/">-->
<!--                    Dummy link-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </nav>-->

            <account-link>
              <a class="not-anonymous" href="/account">
                <figure>
                  <img
                    src="${app.session?.user?.profile_img_url ||
                    "/assets/img/profile-picture.webp"}"
                    onerror="this.src = '/assets/img/profile-picture.webp'"
                    alt="User profile picture"
                  />
                </figure>

                <strong>${app.session?.user?.firstname || "Anonymous"}</strong>
              </a>
            </account-link>
          </div>
        </page-header>

        <section class="card col-3">
          <comparison-stat
            score="${this.salesThisMonth}"
            oldScore="${this.salesLastMonth}"
            ?loading="${this.salesThisMonth === undefined}"
            label="Sales this month (30 days)"
            icon="metrics"
            prefix="€"
            color="yellow"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.activeUsers}"
            ?loading="${this.activeUsers === undefined}"
            label="Active users (30 days)"
            icon="people"
            prefix=""
            color="blue"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.newUsersThisWeek}"
            oldScore="${this.newUsersLastWeek}"
            ?loading="${this.newUsersThisWeek === undefined}"
            compareTime="week"
            label="New users this week"
            icon="people"
            prefix=""
            color="blue"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.totalUsers}"
            ?loading="${this.totalUsers === undefined}"
            label="Total users"
            icon="people"
            prefix=""
            color="red"
          ></comparison-stat>
        </section>

        <section class="card col-6">
          <badge-tag class="blue mb-tiny">
            Stay informed
          </badge-tag>
          <h2>Recent active users</h2>

          <data-table .data="${this.activeUserList}" ?loading="${this.activeUserList === undefined}">
          </data-table>
        </section>

        <section class="card col-6">
          <badge-tag class="red mb-tiny">
            Stay informed
          </badge-tag>
          <h2>Recent Questionnaire Changes</h2>

          <data-table .data="${this.recentQuestionnaires}" ?loading="${this.recentQuestionnaires === undefined}">
          </data-table>
        </section>

        <div hidden>
          <section class="card col-3">
            <h2 class="accent-ball yellow">Pie chart</h2>
            <apex-chart options="${pieChartJsonString}"></apex-chart>
          </section>

          <section class="card col-3">
            <h2 class="accent-ball yellow">Pie chart</h2>
            <apex-chart options="${pieChartJsonString}"></apex-chart>
          </section>

          <section class="card col-3">
            <h2 class="accent-ball yellow">Pie chart</h2>
            <apex-chart options="${pieChartJsonString}"></apex-chart>
          </section>

          <section class="card col-3">
            <h2 class="accent-ball yellow">Pie chart</h2>
            <apex-chart options="${pieChartJsonString}"></apex-chart>
          </section>

          <section class="card col-4">
            <h3 class="accent-ball green">Weekly ranking</h3>

            <data-ranking></data-ranking>
          </section>

          <section class="card col-4">
            <h3 class="accent-ball light-blue">Monthly ranking</h3>

            <data-ranking></data-ranking>
          </section>
          <section class="card col-4">
            <h3 class="accent-ball yellow">Yearly ranking</h3>

            <data-ranking></data-ranking>
          </section>

          <section class="card col-6">
            <h2 class="accent-ball yellow">Line chart</h2>
            <apex-chart options="${lineChartJsonString}"></apex-chart>
          </section>

          <section class="card col-6">
            <h2 class="accent-ball yellow">Area chart</h2>
            <apex-chart options="${areaChartJsonString}"></apex-chart>
          </section>

          <section class="card col-12">
            <h2 class="accent-ball yellow">Columns chart</h2>
            <apex-chart options="${columnChartJsonString}"></apex-chart>
          </section>
        </div>
      `;
    }
  }
);
