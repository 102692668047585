import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-organisation-category-list",
  class PageOrganisationCategoryList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = true;
    searchable = true;
    title = 'Organisation Category List';
    topLinks = links

    editUrl(obj) {
      return `/tenants/organisation_categories/${obj.id}`;
    }

    newUrl() {
      return `/tenants/organisation_categories/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisation_categories/${obj.id}/destroy`;
    }

    constructor() {
      super(new GenericDomain('/organisation_categories'));
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: true},
      ];
    }
  }
);
