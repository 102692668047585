import { GenericDomain } from "../../domain/generic-domain";
import { AdminDestroyPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

customElements.define(
  "page-option-category-destroy",
  class OptionCategoryDestroy extends RoleMixin(
    Session.ROLE_QOGNI_ADMIN,
    AdminDestroyPage
  ) {
    title = "Option delete";

    static get properties() {
      return {
        optionCategoryId: { type: String, attribute: "option-category-id" },
      };
    }

    constructor() {
      super(new GenericDomain('/option_categories'));
    }

    connectedCallback() {
      this.domain.optionId = this.optionCategoryId;
      super.connectedCallback();
    }
  }
);
