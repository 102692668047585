import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-organisation-user-list",
  class PageOrganisationUserList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = false;
    canView = true;
    searchable = true;
    title = null;

    viewLinkIcon = 'arrow';

    static get properties() {
      return {
        ...super.properties,
        organisationId: { type: String, attribute: 'organisation-id' },
        locationId: { type: String, attribute: 'location-id' },
        departmentId: { type: String, attribute: 'department-id' },
      };
    }

    editUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/users/${obj.id}`;
    }

    newUrl() {
      return `/tenants/organisations/${this.organisationId}/users/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/users/${obj.id}/destroy`;
    }

    viewUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/users/${obj.id}/view`;
    }

    constructor() {
      super(new GenericDomain());
    }

    get fetchOptions() {
      return {
        organisation_id: this.organisationId,
        department_id: this.departmentId,
        location_id: this.locationId,
      };
    }

    connectedCallback() {
      this.domain.setBaseUrl(`/organisations/${this.organisationId}/users`);
      super.connectedCallback();
    }

    get columns() {
      return [
        {name: 'Firstname', field: 'firstname', sortable: true, filterable: false, searchable: true},
        {name: 'Lastname', field: 'lastname', sortable: true, filterable: false, searchable: true},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
