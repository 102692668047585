import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage, AdminPage, AdminViewPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {OrderDomain} from "../../domain/order-domain";
import {html} from "lit";
import {dateFormat} from "@qogni-technologies/design-system/src/shared/common";
import {Task} from "@qogni-technologies/design-system/src/shared/task";
import {repeat} from "lit/directives/repeat.js";

customElements.define(
  "page-order-view",
  class PageOrderView extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminViewPage) {
    title = 'Order List';

    static properties = {
      ...super.properties,
      shippingFormVisible: {type: Boolean, attribute: false},
      refundFormVisible: {type: Boolean, attribute: false},
    }

    constructor() {
      super(new OrderDomain());
      this.shippingFormVisible = false;
    }

    #refundClick() {
      this.refundFormVisible = !this.refundFormVisible;
    }
    #sendClick() {
      this.shippingFormVisible = !this.shippingFormVisible;
    }
    #sendAction(e) {
      e.preventDefault();
      if (e.detail?.name !== '--submit') return;
      Task.run(async () => {
        try {
          await this.domain.shipOrder(this.id, {
            inform_customer: !!e.detail.value.inform_customer,
            tracking_link: e.detail.value.tracking_link,
          });
          this.shippingFormVisible = false;
        } catch (e) {
          if (e?.errorData?.message)
            return app.addToastMessage(e?.errorData?.message);
          return app.addToastMessage('Failed marking order as shipped!');
        }

        // reload order.
        this.order = (await this.domain.show(this.id)).data;
        app.addToastMessage('Marked as shipped!');
      }, {
        ghost: window.documentElement,
        description: 'Mark order as shipped.'
      });
    }
    #refundAction(e) {
      e.preventDefault();
      if (e.detail?.name !== '--submit') return;
      Task.run(async () => {
        await this.domain.cancelOrder(this.id, {
          inform_customer: !!e.detail.value.inform_customer,
          issue_refund: !!e.detail.value.stripe_refund,
          reason: e.detail.value.reason,
        });
        this.order = (await this.domain.show(this.id)).data;
      }, {
        ghost: window.documentElement,
      });
    }

    #downloadInvoice(e) {
      Task.run(async () => {
        try {
          const response = await this.domain.downloadInvoicePdf(this.id);
          if (response.data.hosted_page) window.open(response.data.hosted_page, '_blank');
        } catch (e) {
          if (e?.errorData?.message)
            return app.addToastMessage(e?.errorData?.message);
          return app.addToastMessage('Failed downloading PDF');
        }
      }, {
        ghost: e.target,
      });
    }

    render() {
      if (this.loading) return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
      `;

      return html`
        <section class="card">
          <flex-container breakpoint="tiny">
            <flex-item>
              <a href="javascript:history.back()" class="button round tiny">Back</a>
            </flex-item>
            <flex-item>
              <h1>Order ${this.object.order_number}</h1>
            </flex-item>
          </flex-container>
        </section>

        <flex-container breakpoint="normal">
          <flex-item class="col-6">
            <section class="card">
              <h3>Customer</h3>
              <div class="data-table">
                <table>
                  <tbody>
                  <tr>
                    <td class="bold">Name</td>
                    <td>
                      ${this.object.customer_firstname} ${this.object.customer_lastname}
                    </td>
                  </tr>
                  <tr>
                    <td class="bold">Email</td>
                    <td>
                      ${this.object.customer?.email}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </flex-item>

          <flex-item class="col-6">
            <section class="card">
              <h3>Company</h3>
              ${this.object.organisation ? html`
                <div class="data-table">
                  <table>
                    <tbody>
                    <tr>
                      <td class="bold">Name</td>
                      <td>
                        <a href="/tenants/organisations/${this.object.organisation.id}/view">
                          ${this.object.organisation.name}
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ` : html`-`}
            </section>
          </flex-item>
        </flex-container>

        <section class="card">
          <h3>
            Order lines
          </h3>

          <div class="data-table">
            <table>
              <thead>
              <tr>
                <th>Name</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Subtotal</th>
              </tr>
              </thead>
              <tbody>
              ${repeat(this.object.lines, (line) => {
                const currency = line.currency;
                return html`
                <tr>
                  <td>${line.name}</td>
                  <td>
                    ${line.product ? html`
                      <a href="/products/${line.product.id}">
                        ${line.product.name}
                      </a>
                    ` : html`
                      -
                    `}
                  </td>
                  <td>
                    ${line.quantity}
                  </td>
                  <td>
                    ${new Intl.NumberFormat(navigator.languages, {style: 'currency', currency: line.currency.code}).format(line.price)}
                  </td>
                  <td>
                    ${new Intl.NumberFormat(navigator.languages, {style: 'currency', currency: line.currency.code}).format(line.subtotal)}
                  </td>
                </tr>
              `;
      })}
              </tbody>
            </table>
          </div>
        </section>

        <section class="card">
          <h3>
            Changes
          </h3>

          <div class="data-table">
            <table>
              <thead>
              <tr>
                <th>Internal Note</th>
                <th>Public Note</th>
                <th>Status</th>
                <th>User behind action</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              ${repeat(this.object.changes, (change) => html`
                <tr>
                  <td>${change.internal_description}</td>
                  <td>${change.public_description}</td>
                  <td>${change.status}</td>
                  <td>${change.user ? html`${change.user.firstname} ${change.user.lastname}` : html`-`}</td>
                  <td>
                    ${dateFormat(new Date(change.created_at), 'dd-mm-yyyy HH:MM:ss')}
                  </td>
                </tr>
              `)}
              </tbody>
            </table>
          </div>
        </section>

        <section class="card" ?hidden=${!this.shippingFormVisible}>
          <h2>Ship order</h2>
          <x-form @action=${this.#sendAction.bind(this)} unsanitized>
            <form>
              <input
                data-label="Tracking link"
                name="tracking_link" />

              <label>
                <input class="variant1" type="checkbox" value="1" name="inform_customer" checked>
                <span></span>Inform user (send email with tracking code or shipping notification)
              </label>

              <button type="submit">
                Issue change
              </button>
            </form>
          </x-form>
        </section>

        <section class="card" ?hidden=${!this.refundFormVisible}>
          <h2>Cancel/Refund order</h2>
          <x-form @action=${this.#refundAction.bind(this)} unsanitized>
            <form>
              <label>
                Reason
                <textarea name="reason" rows="2"></textarea>
              </label>

              <label>
                <input class="variant1" type="checkbox" value="1" name="stripe_refund" checked>
                <span></span>Refund with Stripe
              </label>

              <label>
                <input class="variant1" type="checkbox" value="1" name="inform_customer" checked>
                <span></span>Inform user
              </label>

              <button type="submit">
                Issue change
              </button>
            </form>
          </x-form>
        </section>

        <section class="card">
          <h2>Actions</h2>
          <flex-container breakpoint="tiny">
            <flex-item class="col-4">
              <button type="button"
                      class="button wide"
                      @click=${this.#sendClick.bind(this)}
                      ?disabled=${this.object.status !== 'pending'}>
                Send shipment
              </button>
            </flex-item>
            <flex-item class="col-4">
              <button type="button"
                      class="button wide"
                      @click=${this.#refundClick.bind(this)}
                      ?disabled=${this.object.status === 'cancelled' || this.object.status === 'refunded'}>
                Cancel & Refund
              </button>
            </flex-item>
            <flex-item class="col-4">
              <button type="button"
                      class="button wide" @click=${this.#downloadInvoice.bind(this)}>
                Download Invoice
              </button>
            </flex-item>
          </flex-container>
        </section>
      `;
    }
  }
);
