import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-product-edit",
  class PageProductEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    #currencyDomain;
    #programDomain;
    constructor() {
      super(new GenericDomain('/products'));
      this.#currencyDomain = new GenericDomain('/currencies');
      this.#programDomain = new GenericDomain('/programs');
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: true,
          type: String,
          markdown: true,
        }, {
          label: 'Price',
          property: 'price',
          type: Number,
        }, {
          label: 'Currency',
          property: 'currency_id',
          type: 'Select',
          choices: async () => {
            const response = await this.#currencyDomain.list({per_page: 500});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name + ' (' + c.code + ')'
              };
            })
          }
        }, {
          label: 'Program',
          property: 'program_id',
          type: 'Select',
          choices: async () => {
            const response = await this.#programDomain.list({per_page: 500});
            return response.data.map((c) => {
              return {value: c.id, name: c.name};
            })
          }
        }
      ];
    }
  }
);
