import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

customElements.define(
  "page-glossary-definition-edit",
  class PageGlossaryDefinitionEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    title = 'Glossary Definition';

    constructor() {
      super(new GenericDomain('/glossary_definitions'));
    }

    async preprocess(data) {
      const processed = await super.preprocess(data);
      if (! processed['url']) delete processed['url'];
      return processed;
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'URL',
          property: 'url',
          required: false,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: false,
          type: String,
          expanded: true,
        }, new TagField({
          object: this.object,
          onChange: (e) => {
            if (! this.object) this.object = {tags: []};
            this.object.tags = e.detail.tags;
            this.requestUpdate();
          },
        }),
      ];
    }
  }
);
