import { GenericDomain } from "./generic-domain";

export class LedgerAccountDomain extends GenericDomain {
  constructor(organisationId) {
    super(`/organisations/${organisationId}/ledger_accounts`);
  }

  async createOrder(data) {
    return this.api.postData(`${this.baseUrl('other')}/create_order`, data);
  }
}
