import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

customElements.define(
  "page-article-category-list",
  class PageArticleCategoryList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = false;
    title = 'Article Categories';

    constructor() {
      super(new GenericDomain('/article_categories'));
    }

    editUrl(obj) {
      return `/content/article_categories/${obj.id}`;
    }

    newUrl() {
      return `/content/article_categories/new`;
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false},
        {name: 'Slug', field: 'slug', sortable: true, filterable: false, searchable: false},
        {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
      ];
    }
  }
);
