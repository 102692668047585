import { GenericDomain } from "../../domain/generic-domain";
import { AdminListPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

customElements.define(
  "page-option-list",
  class PageOptionList extends RoleMixin(
    Session.ROLE_QOGNI_ADMIN,
    AdminListPage
  ) {
    canEdit = true;
    title = "Tip of the days";

    constructor() {
      super(new GenericDomain('/options'));
    }

    editUrl(obj) {
      return `/content/options/${obj.id}`;
    }

    newUrl() {
      return `/content/options/new`;
    }

    destroyUrl(obj) {
      return `/content/options/${obj.id}/destroy`;
    }

    get columns() {
      return [
        {
          name: "Name",
          field: "name",
          sortable: true,
          filterable: false,
          searchable: false,
        },
      ];
    }
  }
);
