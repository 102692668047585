import {RoleMixin} from "../../shared/pwa-page";
import {AdminViewPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {html, nothing} from "lit";
import {QuestionnaireDomain} from "../../domain/questionnaire-domain";
import {UserDomain} from "../../domain/user-domain";
import {repeat} from "lit/directives/repeat.js";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {Converter} from "showdown";

customElements.define(
  "page-questionnaire-view",
  class PageQuestionnaireView extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminViewPage) {
    #userDomain;

    constructor() {
      super(new QuestionnaireDomain());
      this.#userDomain = new UserDomain();
      this.userLoading = false;
    }

    scoring = [
      'Deficient',
      'Insufficient',
      'Marginal',
      'Sufficient',
      'Adequate',
      'Optimal',
    ];

    static get properties() {
      return {
        user: { type: Object },
        userId: { type: String },
        userLoading: { type: Boolean },
        ...super.properties,
      }
    }

    async connectedCallback() {
      // Check for back reference.
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('user_id')) {
        this.userId = urlParams.get('user_id');
        this.userLoading = true;
      }

      await super.connectedCallback();
    }

    get isCompleted() {
      return this.object?.current_step === this.object?.total;
    }

    /**
     * @deprecated
     * @param scoreName
     * @returns {number|null}
     */
    #getScorePercentage(scoreName) {
      const scoreIndex = this.scoring.indexOf(scoreName);
      if (scoreIndex === -1) return null;
      const percentage = ((scoreIndex + 1) / this.scoring.length) * 100;
      return Math.round(percentage);
    }

    async fetch(options) {
      return Promise.all([
        super.fetch(options),
        new Promise(async (resolve, reject) => {
          if (! this.userId) return resolve();
          this.userLoading = true;
          try {
            const res = await this.#userDomain.show(this.userId, {admin: true});
            this.user = res.data;
            this.userLoading = false;
          } catch (err) {
            // totally ignore.
            console.error(err);
            this.userLoading = false;
          }
          return resolve();
        })
      ])
    }

    renderOverview() {
      return html`
      <flex-container breakpoint="normal">
          <flex-item class="col-6">
            <section class="card">
              <h3>Questionnaire details</h3>
              <div class="data-table">
                <table>
                  <tbody>
                    <tr>
                      <td class="bold">
                        Category
                      </td>
                      <td>
                        <a href="/questionnaires/categories/${this.object.questionnaire_category.id}">
                          ${this.object.questionnaire_category?.name ?? 'Unknown Questionnaire'}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">
                        Questions answered v. total
                      </td>
                      <td>
                        ${this.object.current_step} / ${this.object.total}
                        ${this.isCompleted ? html`
                          <badge-tag class="green mb-tiny">Completed</badge-tag>
                        ` : html`
                          <badge-tag class="red mb-tiny">Running...</badge-tag>
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">
                        Score
                      </td>
                      <td>
                        ${this.object.score}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </flex-item>

          <flex-item class="col-6">
            <section class="card">
              <h3>Profiles</h3>
              ${this.object.result_profiles ? html`
                ${repeat(this.object.result_profiles, (r) => html`
                  <section class="card">
                    <a href="/questionnaires/result_profiles/${r.id}" target="_blank">
                      <h4>
                        ${r.name} <badge-tag class="${r.type === 'a' ? 'green' : 'yellow'} mb-tiny filled right">Type ${r.type.toUpperCase()}</badge-tag>
                      </h4>
                    </a>
                    <p>
                      ${unsafeHTML(new Converter().makeHtml(r.subtext))}
                    </p>
                  </section>
                `)}
              ` : html`
                <i>There are no result profiles found for the questionnaire</i>
              `}
            </section>
          </flex-item>
        </flex-container>
      `;
    }

    renderStats() {
      if (! this.object.questionnaire_stats) return nothing;
      if (this.loading) return nothing;

      const stats = [
        {name: 'Cognitive Performance', type: 'cognitive-performance', score: this.object.questionnaire_stats
            ?.filter((n) => n.score_type?.name === "cognitive-performance")
            ?.at(0) ?? null},
        {name: 'Relax & Recovery', type: 'relax-recover', score: this.object.questionnaire_stats
            ?.filter((n) => n.score_type?.name === "relax-recover")
            ?.at(0) ?? null}
      ];

      return html`
        <section class="card">
          <h3>User State</h3>
          <flex-container breakpoint="normal">
            ${repeat(stats.filter(s => !!s.score), (s) => html`
              <flex-item class="col-6">
                <section class="card">
                  ${this.renderVectorStat(s.score, s.type)}
                </section>
              </flex-item>
            `)}
          </flex-container>
        </section>
      `;
    }

    renderVectorStat(score, type) {
      return html`
        <section class="card">
          <hgroup>
            <h2>
              ${type === "cognitive-performance"
              ? html` Cognition and stress resilience `
              : html` Relax & Recover ability `}
            </h2>
          </hgroup>

          <vector-stat
            score="${score.output ? score.output * 100 : this.#getScorePercentage(score.score)}"
            label="${score.score.name}"
            vector="${type === "cognitive-performance" ? "brain" : "person"}"
          ></vector-stat>

          <p>${score.questionnaire_score_text?.text}</p>

          <details class="simple">
            <summary>
              <svg-icon icon="info"></svg-icon>
              More information
            </summary>
            <!-- Content -->
            <section>
              <p>${score.questionnaire_score_description?.description}</p>
            </section>
          </details>
        </section>
      `;
    }

    renderNeurotransmitters() {
      const totals = this.object.totals;
      totals.sort((a, b) => {
        if (a.type === 1 && b.type === null) {
          return -1; // a comes before b
        } else if (a.type === null && b.type === 1) {
          return 1; // b comes before a
        } else if (a.type === b.type) {
          return a.question_category.name.localeCompare(b.question_category.name); // sort alphabetically by name
        } else {
          return 0; // no change for other combinations
        }
      });
      return html`
        <section class="card">
          <h3>Neurotransmitter Values</h3>
          ${this.object.totals ? html`
            <div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th>Neurotransmitter</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                ${repeat(totals, (total) => html`
                  <tr>
                    <td>
                      ${total.question_category?.name}
                    </td>
                    <td>
                      ${total.total}
                    </td>
                    <td>
                      <badge-tag class="${total.type === 1 ? 'green' : 'yellow'} mb-tiny filled right">Type ${total.type === 1 ? 'A' : 'B'}</badge-tag>
                    </td>
                  </tr>
                `)}
                </tbody>
              </table>
            </div>
          ` : html`
            <i>No questionnaires found for the user (none started)</i>
          `}
        </section>
      `;
    }

    render() {
      if (this.loading) return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;

      return html`
        <section class="card">
          <flex-container breakpoint="tiny">
            <flex-item>
              ${this.userId ? html`
                <a href="/tenants/users/${this.userId}" class="button small">Back to user</a>
              ` : html`
                <a href="javascript:history.back()" class="button round tiny">Back</a>
              `}
            </flex-item>
            <flex-item>
              <h1>
                ${this.object.questionnaire_category?.name ?? 'Questionnaire'} results
                ${this.user ? html`for ${this.user.firstname}` : nothing}
              </h1>
            </flex-item>
          </flex-container>
        </section>

        ${this.renderOverview()}
        ${this.renderNeurotransmitters()}
        ${this.renderStats()}
      `;
    }
  }
);
