import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {IngredientDomain} from "../../domain/ingredient-domain";

customElements.define(
  "page-ingredient-edit",
  class PageIngredientEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    #domain;

    title = 'Ingredient';

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Unit',
          property: 'unit',
          required: true,
          type: 'Select',
          choices: ['grams','ml','teaspoon','tablespoon','pinch','kilo','piece','hand','can']
        }, {
          label: 'Vegan',
          property: 'vegan',
          type: 'Checkbox',
        }, {
          label: 'Vegetarian',
          property: 'vegetarian',
          type: 'Checkbox',
        }, {
          label: 'Pescatarian',
          property: 'pescatarian',
          type: 'Checkbox',
        }, {
          label: 'Kcal ',
          property: 'kcal',
          type: Number,
          placeholder: 'Kcal',
        }, {
          label: 'Water ',
          property: 'water',
          type: Number,
          placeholder: 'Water'
        }, {
          label: 'Protein ',
          property: 'protein',
          type: Number,
          placeholder: 'Protein'
        }, {
          label: 'Fat ',
          property: 'fat',
          type: Number,
          placeholder: 'Fat'
        }, {
          label: 'Carbohydrates ',
          property: 'carbohydrates',
          type: Number,
          placeholder: 'Carbohydrates'
        }, {
          label: 'Fibers ',
          property: 'fibers',
          type: Number,
          placeholder: 'Fibers'
        }, {
          label: 'Sugar ',
          property: 'sugar',
          type: Number,
          placeholder: 'Sugar'
        }, {
          label: 'Salt ',
          property: 'salt',
          type: Number,
          placeholder: 'Salt'
        }
      ];
    }

    constructor() {
      super(new IngredientDomain());
    }
  }
);
