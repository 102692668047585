import {html} from "lit";

// eslint-disable-next-line no-undef
const env = require('env');

export const config = {
  baseUrl: env.API_ROOT,

  pages: {
    enter: {
      name: "Enter",
      route: {
        "enter": () => html`
          <page-enter></page-enter>`
      }
    },
    "home": {
      name: "Homepage",
      path: "/",
      primary: true,
      icon: "home",
      bottomMenuIndex: 3,
      route: {
        "": () => html`
          <page-home></page-home>`
      }
    },
    tenants: {
      name: "Tenants",
      icon: "account",
      bottomMenuIndex: 2,
      route: {
        "tenants": () => html`
          <page-tenants></page-tenants>`
      },
      active(name) {
        return [
          'tenants', 'tenants/roles', 'tenants/roles/:id', 'tenants/roles/new', 'tenants/roles/:id/destroy',
          'tenants/users', 'tenants/users/:id', 'tenants/users/:id/view', 'tenants/users/new', 'tenants/users/:id/destroy',
          'tenants/organisation_types', 'tenants/organisation_types/:id',
          'tenants/organisation_categories', 'tenants/organisation_categories/:id',
          'tenants/organisations', 'tenants/organisations/:id', 'tenants/organisations/:id/view',
        ].filter(t => name === t).length > 0;
      }
    },
    tenantOrganisationTypeList: {
      name: "Organisation Type",
      sideMenuIndex: 0,
      route: {
        "tenants/organisation_types": () => html`
          <page-organisation-type-list></page-organisation-type-list>`
      }
    },
    tenantOrganisationTypeEdit: {
      name: "Organisation Type Edit/Create",
      sideMenuIndex: 0,
      route: {
        "tenants/organisation_types/:id": (routeData) => html`
          <page-organisation-type-edit .id=${routeData.pathname.groups.id}></page-organisation-type-edit>`
      }
    },
    tenantOrganisationCategoryList: {
      name: "Organisation Category",
      sideMenuIndex: 0,
      route: {
        "tenants/organisation_categories": () => html`
          <page-organisation-category-list></page-organisation-category-list>`
      }
    },
    tenantOrganisationCategoryEdit: {
      name: "Organisation Category Edit/Create",
      sideMenuIndex: 0,
      route: {
        "tenants/organisation_categories/:id": (routeData) => html`
          <page-organisation-category-edit .id=${routeData.pathname.groups.id}></page-organisation-category-edit>`
      }
    },
    tenantOrganisationList: {
      name: "Organisation",
      sideMenuIndex: 0,
      route: {
        "tenants/organisations": () => html`
          <page-organisation-list></page-organisation-list>`
      }
    },
    tenantOrganisationEdit: {
      name: "Organisation Edit/Create",
      sideMenuIndex: 0,
      route: {
        "tenants/organisations/:id": (routeData) => html`
          <page-organisation-edit .id=${routeData.pathname.groups.id}></page-organisation-edit>`
      }
    },
    tenantOrganisationView: {
      name: "Organisation Detail",
      sideMenuIndex: 0,
      route: {
        "tenants/organisations/:id/view": (routeData) => html`
          <page-organisation-view .id=${routeData.pathname.groups.id}></page-organisation-view>`
      }
    },
    tenantOrganisationLocationEdit: {
      name: "Organisation Location Edit/Create",
      sideMenuIndex: 0,
      route: {
        "tenants/organisations/:organisationId/locations/:id": (routeData) => html`
          <page-organisation-location-edit organisation-id=${routeData.pathname.groups.organisationId} .id=${routeData.pathname.groups.id}></page-organisation-location-edit>`
      }
    },
    tenantOrganisationUserInvite: {
      name: "Invite Users",
      sideMenuIndex: 0,
      route: {
        "tenants/organisations/:organisationId/users/invite": (routeData) => {
          const search = new URLSearchParams(window.location.search);
          return html`
          <page-organisation-user-invite
            organisation-id=${routeData.pathname.groups.organisationId}
            department-id=${search.get('department_id')}
            location-id=${search.get('location_id')}
          ></page-organisation-user-invite>`
        }
      }
    },
    tenantRoleList: {
      name: "Roles",
      sideMenuIndex: 0,
      route: {
        "tenants/roles": () => html`
          <page-role-list></page-role-list>`
      }
    },
    tenantRoleEdit: {
      name: "Role Edit/Create",
      sideMenuIndex: 0,
      route: {
        "tenants/roles/:id": (routeData) => html`
          <page-role-edit .id=${routeData.pathname.groups.id}></page-role-edit>`
      }
    },
    tenantRoleDestroy: {
      sideMenuIndex: 0,
      route: {
        "tenants/roles/:id/destroy": (routeData) => html`
          <page-role-destroy .id=${routeData.pathname.groups.id}></page-role-destroy>`
      }
    },
    tenantUserList: {
      name: "Users",
      sideMenuIndex: 0,
      route: {
        "tenants/users": () => html`
          <page-user-list></page-user-list>`
      }
    },
    tenantUserEdit: {
      name: "User Edit",
      sideMenuIndex: 0,
      route: {
        "tenants/users/:id": (routeData) => html`
          <page-user-edit .id=${routeData.pathname.groups.id}></page-user-edit>`
      }
    },
    tenantUserDestroy: {
      name: "User Destroy",
      sideMenuIndex: 0,
      route: {
        "tenants/users/:id/destroy": (routeData) => html`
          <page-user-destroy .id=${routeData.pathname.groups.id}></page-user-destroy>`
      }
    },
    tenantUserView: {
      name: "User Details",
      sideMenuIndex: 0,
      route: {
        "tenants/users/:id/view": (routeData) => html`
          <page-user-view .id=${routeData.pathname.groups.id}></page-user-view>`
      }
    },
    content: {
      name: "Content",
      icon: "document",
      bottomMenuIndex: 4,
      route: {
        "content": () => html`
          <page-content></page-content>`
      },
      active(name) {
        return [
          'content', 'content/recipes', 'content/recipes/:id', 'content/recipes/:id/destroy',
          'content/ingredients', 'content/ingredients/:id',
          'content/article_categories', 'content/article_categories/:id',
          'content/articles', 'content/articles/:id'
        ].filter(t => name === t).length > 0;
      }
    },
    contentRecipeList: {
      name: "Recipe List",
      route: {
        "content/recipes": () => html`
          <page-recipe-list></page-recipe-list>`
      }
    },
    contentRecipeEdit: {
      name: "Recipe Edit",
      route: {
        "content/recipes/:id": (routeData) => html`
          <page-recipe-edit .id=${routeData.pathname.groups.id}></page-recipe-edit>`
      }
    },
    contentIngredientList: {
      name: "Ingredient List",
      route: {
        "content/ingredients": () => html`
          <page-ingredient-list></page-ingredient-list>`
      }
    },
    contentIngredientEdit: {
      name: "Ingredient Edit",
      route: {
        "content/ingredients/:id": (routeData) => html`
          <page-ingredient-edit .id=${routeData.pathname.groups.id}></page-ingredient-edit>`
      }
    },
    contentArticleCategoryList: {
      name: "Article Category List",
      route: {
        "content/article_categories": () => html`
          <page-article-category-list></page-article-category-list>`
      }
    },
    contentArticleCategoryEdit: {
      name: "Article Category Edit",
      route: {
        "content/article_categories/:id": (routeData) => html`
          <page-article-category-edit .id=${routeData.pathname.groups.id}></page-article-category-edit>`
      }
    },
    contentArticleList: {
      name: "Article List",
      route: {
        "content/articles": () => html`
          <page-article-list></page-article-list>`
      }
    },
    contentArticleEdit: {
      name: "Article Edit",
      route: {
        "content/articles/:id": (routeData) => html`
          <page-article-edit .id=${routeData.pathname.groups.id}></page-article-edit>`
      }
    },
    contentOptionList: {
      name: "Option List",
      route: {
        "content/options": () => html`<page-option-list></page-option-list>`
      }
    },
    contentOptionEdit: {
      name: "Option Edit",
      route: {
        "content/options/:id": (routeData) => html`
          <page-option-edit .id=${routeData.pathname.groups.id}></page-option-edit>`
      }
    },
    contentOptionEditDestroy: {
      name: "Option Destroy",
      route: {
        "content/options/:id/destroy": (routeData) => html`
          <page-option-destroy .id=${routeData.pathname.groups.id}></page-option-destroy>`
      }
    },
    contentOptionCategoryList: {
      name: "Option Category List",
      route: {
        "content/option-categories": () => html`<page-option-category-list></page-option-category-list>`
      }
    },
    contentOptionCategoryEdit: {
      name: "Option Category Edit",
      route: {
        "content/option-categories/:id": (routeData) => html`
          <page-option-category-edit .id=${routeData.pathname.groups.id}></page-option-category-edit>`
      }
    },
    contentOptionCategoryDestroy: {
      name: "Option Category Destroy",
      route: {
        "content/option-categories/:id/destroy": (routeData) => html`
          <page-option-category-destroy .id=${routeData.pathname.groups.id}></page-option-category-destroy>`
      }
    },
    programs: {
      name: "Programs",
      icon: "blocks",
      bottomMenuIndex: 5,
      active(name) {
        return [
          'programs', 'programs/:id'
        ].filter(t => name === t).length > 0;
      },
      route: {
        "programs": () => html`
          <page-program-list></page-program-list>`
      }
    },
    programsEdit: {
      name: "Program Edit",
      route: {
        "programs/:id": (routeData) => html`
          <page-program-edit .id=${routeData.pathname.groups.id}></page-program-edit>`
      }
    },
    social: {
      name: "Social",
      icon: "people",
      route: {
        "social": () => html`
          <page-social></page-social>`
      },
      active(name) {
        return [
          'social', 'social/channels', 'social/channels/:id', 'social/channels/:id/destroy',
          'social/channels/:channelId/admins'
        ].filter(t => name === t).length > 0;
      }
    },
    socialChannelList: {
      name: "Channel List",
      route: {
        "social/channels": () => html`
          <page-channel-list></page-channel-list>`
      }
    },
    socialChannelEdit: {
      name: "Channel Edit",
      route: {
        "social/channels/:id": (routeData) => html`
          <page-channel-edit .id=${routeData.pathname.groups.id}></page-channel-edit>`
      }
    },
    socialChannelDestroy: {
      name: "Channel Destroy",
      route: {
        "social/channels/:id/destroy": (routeData) => html`
          <page-channel-destroy .id=${routeData.pathname.groups.id}></page-channel-destroy>`
      }
    },
    socialChannelAdminList: {
      name: "Channel Admin List",
      route: {
        "social/channels/:channelId/admins": (routeData) => html`
          <page-channel-admin-list .channelId=${routeData.pathname.groups.channelId}></page-channel-admin-list>`
      }
    },
    socialChannelAdminEdit: {
      name: "Channel Admin Edit",
      route: {
        "social/channels/:channelId/admins/:id": (routeData) => html`
          <page-channel-admin-edit .channelId=${routeData.pathname.groups.channelId} .id=${routeData.pathname.groups.id}></page-channel-admin-edit>`
      }
    },
    socialChannelAdminDestroy: {
      name: "Channel Admin Destroy",
      route: {
        "social/channels/:channelId/admins/:id/destroy": (routeData) => html`
          <page-channel-admin-destroy .channelId=${routeData.pathname.groups.channelId} .id=${routeData.pathname.groups.id}></page-channel-admin-destroy>`
      }
    },
    questionnaires: {
      name: "Questionnaires",
      icon: "brain",
      route: {
        "questionnaires": () => html`
          <page-questionnaire></page-questionnaire>`
      },
      active(name) {
        return [
          'questionnaires', 'questionnaires/categories', 'questionnaires/questions', 'questionnaires/result_profiles',
          'questionnaires/result_profiles/:resultProfileId/texts/:id',
          'questionnaires/result_profiles/:resultProfileId/texts/:id/destroy',
          'questionnaires/result_profiles/:id',
          'questionnaires/question_categories', 'questionnaires/question_categories/:id'
        ].filter(t => name === t).length > 0;
      }
    },
    questionnaireView: {
      name: "Questionnaire Details",
      route: {
        "questionnaires/:id/view": (routeData) => html`
          <page-questionnaire-view .id=${routeData.pathname.groups.id}></page-questionnaire-view>`
      }
    },
    questionnaireDestroy: {
      name: "Questionnaire Destroy",
      route: {
        "questionnaires/:id/destroy": (routeData) => html`
          <page-questionnaire-destroy .id=${routeData.pathname.groups.id}></page-questionnaire-destroy>`
      }
    },
    questionnaireCategory: {
      name: "Questionnaire Categories List",
      route: {
        "questionnaires/categories": () => html`
          <page-questionnaire-category-list></page-questionnaire-category-list>`
      }
    },
    questionnaireQuestionList: {
      name: "Question List",
      route: {
        "questionnaires/questions": () => html`
          <page-question-list></page-question-list>`
      }
    },
    questionnaireQuestionEdit: {
      name: "Question Edit",
      route: {
        "questionnaires/questions/:id": (routeData) => html`
          <page-question-edit .id=${routeData.pathname.groups.id}></page-question-edit>`
      }
    },
    questionnaireQuestionCategoryList: {
      name: "Question Category List",
      route: {
        "questionnaires/question_categories": () => html`
          <page-question-category-list></page-question-category-list>`
      }
    },
    questionnaireQuestionCategoryEdit: {
      name: "Question Category Edit",
      route: {
        "questionnaires/question_categories/:id": (routeData) => html`
          <page-question-category-edit .id=${routeData.pathname.groups.id}></page-question-category-edit>`
      }
    },
    questionnaireResultProfileList: {
      name: "Result Profiles",
      route: {
        "questionnaires/result_profiles": () => html`
          <page-result-profile-list></page-result-profile-list>`
      }
    },
    questionnaireResultProfileTextEdit: {
      name: "Result Profile Text Edit",
      route: {
        "questionnaires/result_profiles/:resultProfileId/texts/:id": (routeData) => html`
          <page-result-profile-text-edit result-profile-id=${routeData.pathname.groups.resultProfileId} .id=${routeData.pathname.groups.id}></page-result-profile-text-edit>`
      }
    },
    questionnaireResultProfileTextDestroy: {
      name: "Result Profile Text Destroy",
      route: {
        "questionnaires/result_profiles/:resultProfileId/texts/:id/destroy": (routeData) => html`
          <page-result-profile-text-destroy result-profile-id=${routeData.pathname.groups.resultProfileId} .id=${routeData.pathname.groups.id}></page-result-profile-text-destroy>`
      }
    },
    questionnaireResultProfileEdit: {
      name: "Result Profile Edit",
      route: {
        "questionnaires/result_profiles/:id": (routeData) => html`
          <page-result-profile-edit .id=${routeData.pathname.groups.id}></page-result-profile-edit>`
      }
    },
    products: {
      name: "Products",
      icon: "idea",
      active(name) {
        return [
          'products', 'products/:id'
        ].filter(t => name === t).length > 0;
      },
      route: {
        "products": () => html`
          <page-product-list></page-product-list>`
      }
    },
    productsEdit: {
      name: "Product Edit",
      route: {
        "products/:id": (routeData) => html`
          <page-product-edit .id=${routeData.pathname.groups.id}></page-product-edit>`
      }
    },
    orders: {
      name: "Orders",
      icon: "metrics",
      active(name) {
        return [
          'orders', 'orders/:id'
        ].filter(t => name === t).length > 0;
      },
      route: {
        "orders": () => html`
          <page-order-list></page-order-list>`
      }
    },
    ordersView: {
      name: "Orders",
      icon: "metrics",
      sideMenuIndex: 0,
      route: {
        "orders/:id": (routeData) => html`
          <page-order-view .id=${routeData.pathname.groups.id}></page-order-view>`
      }
    },
    feedback: {
      name: "Feedback",
      icon: "horn",
      active(name) {
        return [
          'feedback', 'feedback/:id', 'feedback/:id/destroy'
        ].filter(t => name === t).length > 0;
      },
      route: {
        "feedback": () => html`
          <page-feedback-list></page-feedback-list>`
      }
    },
    feedbackDestroy: {
      sideMenuIndex: 0,
      route: {
        "feedback/:id/destroy": (routeData) => html`
          <page-feedback-destroy .id=${routeData.pathname.groups.id}></page-feedback-destroy>`
      }
    },
    feedbackView: {
      sideMenuIndex: 0,
      route: {
        "feedback/:id": (routeData) => html`
          <page-feedback-view .id=${routeData.pathname.groups.id}></page-feedback-view>`
      }
    },
    app: {
      name: "App",
      icon: "blocks",
      active(name) {
        return [
          'app',
          'app/tags', 'app/tags/:id',
          'app/interest-topics', 'app/interest-topics/:id',
          'app/glossary-definitions', 'app/glossary-definitions/:id', 'app/glossary-definitions/:id/destroy'
        ].filter(t => name === t).length > 0;
      },
      route: {
        "app": () => html`
          <page-app></page-app>`
      }
    },
    appTagList: {
      name: "Tag List",
      route: {
        "app/tags": () => html`
          <page-tag-list></page-tag-list>`
      }
    },
    appTagEdit: {
      name: "Tag Edit",
      route: {
        "app/tags/:id": (routeData) => html`
          <page-tag-edit .id=${routeData.pathname.groups.id}></page-tag-edit>`
      }
    },
    appInterestTopicList: {
      name: "Interest Topic List",
      route: {
        "app/interest-topics": () => html`
          <page-interest-topic-list></page-interest-topic-list>`
      }
    },
    appInterestTopicEdit: {
      name: "Interest Topic Edit",
      route: {
        "app/interest-topics/:id": (routeData) => html`
          <page-interest-topic-edit .id=${routeData.pathname.groups.id}></page-interest-topic-edit>`
      }
    },
    appGlossaryDefinitionList: {
      name: "Glossary Definition List",
      route: {
        "app/glossary-definitions": () => html`
          <page-glossary-definition-list></page-glossary-definition-list>`
      }
    },
    appGlossaryDefinitionEdit: {
      name: "Glossary Definition Edit",
      route: {
        "app/glossary-definitions/:id": (routeData) => html`
          <page-glossary-definition-edit .id=${routeData.pathname.groups.id}></page-glossary-definition-edit>`
      }
    },
    appGlossaryDefinitionDestroy: {
      route: {
        "app/glossary-definitions/:id/destroy": (routeData) => html`
          <page-glossary-definition-destroy .id=${routeData.pathname.groups.id}></page-glossary-definition-destroy>`
      }
    },
  },
};
