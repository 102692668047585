import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { ChannelAdminDomain } from "../../domain/channel-admin-domain";
import { UserAutocomplete } from "../../shared/autocompletes";
import { createRef } from "lit/directives/ref.js";

customElements.define(
  "page-channel-admin-edit",
  class PageChannelAdminEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    title = 'Channel Admin';

    static get properties() {
      return {
        channelId: { type: String, attribute: 'channel-id' },
      };
    }

    get fields() {
      const userRef = createRef();
      return [
        {
          label: 'User',
          property: 'user_id',
          required: true,
          type: String,
          ref: userRef,
          autoComplete: new UserAutocomplete(userRef).autoComplete
        }, {
          label: 'Role',
          property: 'role',
          type: 'Select',
          required: true,
          choices: ['owner', 'editor'],
          help: 'Owner can edit other admins, editor can only post in the channel.'
        }
      ];
    }

    constructor() {
      super(new ChannelAdminDomain());
    }

    connectedCallback() {
      this.domain.channelId = this.channelId;
      super.connectedCallback();
    }
  }
);
