import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RecipeDomain} from "../../domain/recipe-domain";
import {html} from "lit";
import {repeat} from "lit/directives/repeat.js";
import {Task} from "@qogni-technologies/design-system/src/shared/task";
import {IngredientDomain} from "../../domain/ingredient-domain";
import {AutoComplete} from "@qogni-technologies/design-system/src/components/base/form/auto-complete";
import {createRef, ref} from "lit/directives/ref.js";
import {askConfirm} from "@qogni-technologies/design-system/src/components/base/modal-dialog";

customElements.define(
  "page-recipe-edit",
  class PageRecipeEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    #ingredientDomain;

    #ingredientList;
    #unitList;
    #ingredientNameInputRef = createRef();
    #ingredientUnitInputRef = createRef();
    #ingredientQuantityInputRef = createRef();

    title = 'Recipe';

    constructor() {
      super(new RecipeDomain());
      this.#ingredientDomain = new IngredientDomain();
    }

    async preprocess(data) {
      data = await super.preprocess(data);
      if (data.image && data.image.name === '' && data.image.size === 0) {
        delete data.image;
      }
      return data
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: true,
          type: String,
          expanded: true,
          html: true,
        }, {
          label: 'Minutes',
          property: 'minutes',
          required: false,
          type: Number,
        }, {
          label: 'Total kcal\'s',
          property: 'total_kcal',
          disabled: true,
          type: Number,
        }, {
          label: 'Breakfast',
          property: 'breakfast',
          type: 'Checkbox',
        }, {
          label: 'Lunch',
          property: 'lunch',
          type: 'Checkbox',
        }, {
          label: 'Dinner',
          property: 'dinner',
          type: 'Checkbox',
        }, {
          label: 'Image Source',
          property: 'image_source',
          required: false,
          type: String,
        }, {
          label: 'Image',
          property: 'image',
          type: 'Image',
          required: false,
          mime: ['image/*'],
          maxSize: 10000000,
          getter: (f, obj) => {
            return obj.image_url;
          },
          delete: (f) => {
            alert('delete file');
          }
        }, {
        // Pivot of Ingredients.
          getter: (f, obj) => {
            return obj.ingredients;
          },
          render: this.renderPivot.bind(this),
        }
      ];
    }

    async deletePivotEntry(ingredientId) {
      const adjustedData = [];
      Object.entries(this.object.ingredients.filter((i) => i.id !== ingredientId)).forEach(([_, value]) => {
        adjustedData.push({
          id: value.id,
          amount: value.pivot.amount
        });
      });

      Task.run(async () => {
        await this.domain.update(this.id, {ingredients: adjustedData});
        this.object = (await this.domain.show(this.id)).data;
        this.requestUpdate();
      });
    }

    renderPivot(f, value) {
      const deleteAction = async (e) => {
        e.preventDefault();
        await this.deletePivotEntry(e.target.parentNode.dataset.ingredient);
      };

      return html`
        <h4>Ingredients</h4>
        <section class="card">
          ${repeat(value, (v) => html`
            <section class="card" data-recipe="${v.pivot.recipe_id}" data-ingredient="${v.pivot.ingredient_id}" data-amount="${v.pivot.amount}">
              ${v.name} (${v.unit}): ${v.pivot.amount}
              <button class="simple mb-tiny" @click=${deleteAction} style="float: right; margin-top: 5px;">
                <svg-icon icon="trash"></svg-icon>
                Delete
              </button>
            </section>
          `)}
        </section>
        <section class="card">
          <flex-container breakpoint="tiny">
            <flex-item class="col-4">
              <input data-label="Ingredient name"
                     placeholder="ingredient name"
                     @focus=${(e) => AutoComplete.connect(e, this.ingredientAutocomplete)}
                     @input=${(e) => e.stopPropagation()}
                     @change=${this.ingredientNameChange.bind(this)}
                     ${ref(this.#ingredientNameInputRef)} />
            </flex-item>
            <flex-item class="col-4">
              <input type="number"
                     data-label="Quantity"
                     placeholder="Quantity"
                     ${ref(this.#ingredientQuantityInputRef)}
              />
            </flex-item>
            <flex-item class="col-4">
              <input type="text"
                     data-label="Unit"
                     placeholder="Unit"
                     @focus=${(e) => AutoComplete.connect(e, this.unitAutocomplete)}
                     ${ref(this.#ingredientUnitInputRef)} />
            </flex-item>
          </flex-container>
          <button type="button" class="wide outline" @click="${this.addIngredient.bind(this)}">Add ingredient</button>
        </section>
      `;
    }

    async addIngredient(e) {
      // Validate form.
      if (! this.#ingredientNameInputRef.value.value) return app.addToastMessage('Ingredient name can\'t be empty');
      if (! this.#ingredientQuantityInputRef.value.value) return app.addToastMessage('Ingredient quantity can\'t be empty');
      if (! this.#ingredientUnitInputRef.value.value) return app.addToastMessage('Ingredient unit can\'t be empty');

      if (! this.#ingredientList || this.#ingredientList.filter((i) => i.name === this.#ingredientNameInputRef.value.value.trim()).length === 0) {
        if (! (await askConfirm({message: 'The ingredient doesn\'t exist yet, it will be created.'}))) {
          return;
        }
      }

      Task.run(async () => {
        let ingredient = {};

        if (! this.#ingredientList || this.#ingredientList.filter((i) => i.name === this.#ingredientNameInputRef.value.value.trim()).length === 0) {
          // Create ingredient first.
          const response = await this.#ingredientDomain.create({
            name: this.#ingredientNameInputRef.value.value,
            unit: this.#ingredientUnitInputRef.value.value,
          });
          ingredient = {
            id: response.data.id,
            name: response.data.name,
            unit: response.data.unit,
            amount: parseInt(this.#ingredientQuantityInputRef.value.value)
          };
        } else {
          // Get existing ingredient ID.
          const existing = this.#ingredientList.filter((i) => i.name === this.#ingredientNameInputRef.value.value.trim())[0];
          ingredient = {
            id: existing.id,
            name: existing.name,
            unit: existing.unit,
            amount: parseInt(this.#ingredientQuantityInputRef.value.value)
          }
        }

        // Update recipe, add the ingredient, and reload.
        await this.domain.update(this.object.id, {
          ingredients: [
            ...this.object.ingredients.map((i) => {return {
              id: i.id,
              name: i.name,
              unit: i.unit,
              amount: i.pivot.amount,
            }}), ingredient
          ]
        });

        // Reload.
        await this.fetch();

      }, {
        ghost: document.documentElement,
        description: 'Create ingredient entry for the recipe.'
      });
    }

    ingredientNameChange(e) {
      if (! this.#ingredientList || this.#ingredientList.filter((i) => i.name === e.target.value.trim()).length === 0) {
        this.#ingredientUnitInputRef.value.value = '';
        this.#ingredientUnitInputRef.value.disabled = false;
        return;
      }

      this.#ingredientUnitInputRef.value.value = this.#ingredientList.filter((i) => i.name === e.target.value.trim())[0]?.unit;
      this.#ingredientUnitInputRef.value.disabled = true;
    }

    get ingredientAutocomplete() {
      return {
        categories: {
          Ingredients: {
            sortIndex: 1,
            trigger: (options) => {
              return options.search.length >= 2;
            },
            action: (options) => {
              this.#ingredientNameInputRef.value.value = options.text;
            },
            getItems: async (options) => {
              if (!this.#ingredientList || Object.keys(this.#ingredientList).length === 0)
                this.#ingredientList = (await this.#ingredientDomain.list({per_page: 1000})).data;

              return this.#ingredientList
                .filter((i) => i.name.toLowerCase().indexOf(options.search.toLowerCase()) !== -1)
                .map((i) => {
                return {
                  text: i.name,
                  description: i.unit
                };
              });
            },
          },
        },
      };
    }

    get unitAutocomplete() {
      return {
        categories: {
          Unit: {
            sortIndex: 1,
            trigger: (options) => {
              return options.search.length >= 0;
            },
            action: (options) => {
              this.#ingredientUnitInputRef.value.value = options.text;
            },
            getItems: async (options) => {
              if (!this.#ingredientList || Object.keys(this.#ingredientList).length === 0)
                this.#ingredientList = (await this.#ingredientDomain.list({per_page: 1000})).data;

              if (! this.#unitList) {
                this.#unitList = this.#ingredientList.reduce((acc, cur) => {
                  if(!acc.includes(cur.unit)) {
                    acc.push(cur.unit);
                  }
                  return acc;
                }, []);
              }

              return this.#unitList
                .filter((i) => i.toLowerCase().indexOf(options.search.toLowerCase()) !== -1)
                .map((i) => {
                return {
                  text: i,
                };
              });
            },
          },
        },
      };
    }
  }
);
