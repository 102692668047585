import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

customElements.define(
  "page-channel-edit",
  class PageChannelEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    title = 'Channel';

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Slug',
          property: 'slug',
          required: false,
          type: String
        }, {
          label: 'Description',
          property: 'description',
          required: false,
          type: String,
          expanded: true,
          markdown: true,
          help: 'Leave this empty to be auto generated based on the name',
        }, new TagField({
          object: this.object,
          onChange: (e) => {
            if (! this.object) this.object = {tags: []};
            this.object.tags = e.detail.tags;
            this.requestUpdate();
          },
        }),
      ];
    }

    constructor() {
      super(new GenericDomain('/channels'));
    }
  }
);
