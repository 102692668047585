import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage, AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {FeedbackDomain} from "../../domain/feedback-domain";
import {ResultProfileTextDomain} from "../../domain/result-profile-text-domain";

customElements.define(
  "page-result-profile-text-destroy",
  class PageResultProfileTextDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
    constructor() {
      super(new ResultProfileTextDomain());
    }

    redirect() {
      window.location.replace(`/questionnaires/result_profiles/${this.resultProfileId}`);
    }

    static get properties() {
      return {
        resultProfileId: { type: String, attribute: 'result-profile-id' },
      };
    }

    connectedCallback() {
      this.domain.resultProfileId = this.resultProfileId;
      super.connectedCallback();
    }
  }
);
