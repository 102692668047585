import { RoleMixin } from "../../shared/pwa-page";
import { AdminDestroyPage, AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-channel-destroy",
  class PageChannelDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
    title = 'Channel';

    constructor() {
      super(new GenericDomain('/channels'));
    }
  }
);
