import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {ResultProfileDomain} from "../../domain/result-profile-domain";
import {html, nothing} from "lit";

customElements.define(
  "page-result-profile-edit",
  class PageResultProfileEditPage extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    constructor() {
      super(new ResultProfileDomain());
    }

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Sub-text',
          property: 'subtext',
          required: false,
          type: String,
          markdown: true,
        }, {
          label: 'Type (A = current profile, based on A-questions, B = potential profile, based on B-questions)',
          property: 'type',
          required: true,
          type: 'Select',
          choices: ['a', 'b']
        }, {
          render() {
            return html`<h3>Neurotransmitters</h3>`
          }
        }, {
          label: 'Dopamine',
          property: 'dopamine',
          type: 'Checkbox',
          required: false,
        }, {
          label: 'Acetylcholine',
          property: 'acetylcholine',
          type: 'Checkbox',
          required: false,
        }, {
          label: 'GABA',
          property: 'gaba',
          type: 'Checkbox',
          required: false,
        }, {
          label: 'Serotonin',
          property: 'serotonin',
          type: 'Checkbox',
          required: false,
        }, {
          render: () => {
            if (! this.id) return html`
              <h3>Text entries</h3>
              <p>In order to add text entries you should first save the result profile, and reopen it.</p>
            `;
            return html`
              <h3>Text entries</h3>
              <page-result-profile-text-list result-profile-id="${this.id}"></page-result-profile-text-list>
            `;
          }
        }, new TagField({
          object: this.object,
          onChange: (e) => {
            if (! this.object) this.object = {tags: []};
            this.object.tags = e.detail.tags;
            this.requestUpdate();
          },
        })
      ];
    }
  }
);
