import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

customElements.define(
  "page-article-edit",
  class PageArticleEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
    #categoryDomain;
    title = 'Article';

    get fields() {
      return [
        {
          label: 'Name',
          property: 'name',
          required: true,
          type: String
        }, {
          label: 'Slug',
          property: 'slug',
          required: false,
          type: String,
          help: 'Leave this empty to be auto generated based on the name',
        }, {
          label: 'Category',
          property: 'category_id',
          required: true,
          type: 'Select',
          choices: async () => {
            const response = await this.#categoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            })
          }
        }, {
          label: 'Content',
          property: 'content',
          required: true,
          type: String,
          markdown: true,
        }
      ];
    }

    constructor() {
      super(new GenericDomain('/articles'));
      this.#categoryDomain = new GenericDomain('/article_categories');
    }
  }
);
