import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";

customElements.define(
  "page-organisation-list",
  class PageOrganisationList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canView = true;
    canDestroy = true;
    canAdd = true;
    searchable = true;
    title = 'Organisation List';
    topLinks = links

    viewLinkIcon = 'arrow';
    viewLinkText = 'Manage';
    viewLinkWidth = 90;

    editUrl(obj) {
      return `/tenants/organisations/${obj.id}`;
    }

    viewUrl(obj) {
      return `/tenants/organisations/${obj.id}/view`;
    }

    newUrl() {
      return `/tenants/organisations/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisations/${obj.id}/destroy`;
    }

    constructor() {
      super(new GenericDomain('/organisations'));
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: true},
        {name: 'Type', field: 'organisation_type_id', sortable: true, render: (obj, value) => html`${obj.organisation_type?.name}`},
        {name: 'Category', field: 'organisation_category_id', sortable: true, render: (obj, value) => html`${obj.organisation_category?.name}`},
        {name: 'GEO', field: 'latitude', sortable: true, render: (obj, value) => html`
          ${value ? html`<svg-icon icon="check" display="inline" color="green" size="11px" display="inline"></svg-icon>` : html`<svg-icon icon="close" display="inline" color="red" size="11px"></svg-icon>`}
        `},
        {name: 'VAT', field: 'vat_number', sortable: true, searchable: true, render: (obj, value) => html`
          ${! value ? html`-` : html`
            ${obj.vat_number_verified_at ? html`
              <span title="Verified at ${new Date(obj.vat_number_verified_at).format({mode: 'format'})}">
                <svg-icon icon="check" display="inline" color="green" size="11px" display="inline"></svg-icon>&nbsp;${value}
              </span>
            ` : html`
              <svg-icon icon="close" display="inline" color="red" size="11px"></svg-icon>
              ${value}
            `}
          `}
        `},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
